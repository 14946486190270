$paddingRow: 1.688rem;


.modal {
  padding: 1.313rem 0 0.75rem;

  & header {
    padding: 0 $paddingRow !important;

    & span {
      margin-top: 0 !important;
    }
  }
}

.formContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 $paddingRow;
  max-width: 45.813rem;

  & .fieldContainer:nth-child(1),
  & .fieldContainer:nth-child(2),
  & .fieldContainer:nth-child(3) {
    width: 32%;
    margin-top: 0.75rem;
  }

  & .fieldContainer:nth-child(4),
  & .fieldContainer:nth-child(5) {
    width: 49%;
    margin: 1.375rem 0;
  }

  & .fieldContainer:nth-child(6) {
    width: 100%;
  }
}

.fieldContainer {
  & label, & input, & textarea {
    color: #000;
  }
}

.decomposeFieldContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.field {
  height: 2.64rem;
  border-radius: 0.5rem;
}

.textarea {
  max-height: 12.5rem;
  border-radius: 0.5rem;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.813rem;
  width: 100%;
  margin-top: 1.5rem;
  background-color: #fff;
  border-top: 1px solid var(--color-border-field20);

  & button:first-child {
    margin-right: 0.781rem;
  }
}