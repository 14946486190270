.pair {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.25rem;

  @media (max-width: 1440px) {
    grid-template-columns: 0.9fr 1fr;
  }

  &.centered {
    align-items: center;
  }
}

.date {
  display: flex;
  align-items: center;

  & svg {
    margin-left: 0.5rem;
    margin-right: 1rem;
  }
}

.day {
  font-weight: 500;
}

.pass {
  display: flex;
  align-items: center;
  white-space: nowrap;

  & b {
    flex-basis: 30%;
  }
}

.info {
  display: flex;
  flex-direction: column;

  & b {
    margin-bottom: 0.75rem;
  }
}

////////////////////////////

.hr {
  width: 100%;
  height: 1px;
  background: #dbdbdb;
  margin-bottom: 1rem;
}

.mb {
  margin-bottom: 1.25rem;
}

.navbar {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  width: 100%;

  & span {
    font-size: 1.125rem;
    margin-left: 1.8rem;
  }
}

///
.subtitle {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.75rem;
}

.mb0 {
  margin-bottom: 0;
}

.value {
  margin-top: 1rem;
}

.flex {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.period {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 1.5rem;
}

.back {
  margin-bottom: 1.5rem;
}

.list {
  padding: 1.5rem;
}

.mt0 {
  margin-top: 0 !important;
}

.emptyPlaceholder {
  text-align: center;
}

.relationsTabs {
  width: min-content;
  margin: 0 auto 1.375rem;
}

.relationsTab {
  width: 12rem;
}