.info > svg{
  cursor: default;
  position: relative;
  min-width: 1.625rem;
  min-height: 1.625rem;
}

.infoPopover{
  width: max-content;
  z-index: 2;
  position: absolute;
  right: .5rem;
}

.infoPopoverTitle{
  font-size: 1rem;
  color: #727272;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.infoPopoverContainer{
  font-weight: 400;
  transition: .1s all ease-in-out;
  color: black;
  outline: 1px solid #4FB2ED;
  background-color: white;
  border-radius: .375rem;
  padding: .5rem .625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.infoPopoverHeading, .infoPopoverItem{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  padding: .25rem 0;
  font-size: .875rem;
  width: 100%;
}

.infoPopoverHeading{
  justify-content: space-between;
}

.infoPopoverItem{
  justify-content: flex-end;
  & > span {
    text-align: right;
  }
}

.infoPopoverItemMarker{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: .25rem;
  margin-right: auto;
}

.square{
  width: .625rem;
  height: .625rem;
}

.legend{
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-auto-rows: 1fr;
  padding: .25rem 0;
  font-size: .875rem;
  grid-row-gap: .5rem;
  grid-column-gap: 1rem;
  width: 100%;
  & > span{
    text-align: right;
  }
}