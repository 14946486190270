.monthsLine {
  position: sticky;
  top: 0;
  margin-left: 35%;
  width: auto;
  z-index: 97;
}

.monthCaptionBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  position: absolute;
  top: 0;
  height: 2.81rem;
  background-color: #f4f4f4;
  border-bottom: 1px solid rgba(177, 177, 177, 1);
}

.monthInterval {
  color: #4fb1eb;
  font-size: 0.875rem;
}

.currentLine {
  position: absolute;
  width: 0.1875rem;
  min-height: calc(100vh - 5.62rem);
  top: 2.81rem;
  background-color: #707070;
  z-index: 0;
}

.currentDay {
  position: absolute;
  height: 100%;
  top: 2.5rem;
  z-index: 1;
  transform: translateX(-50%);
  span {
    background: #4fb2ed;
    border-radius: 1rem;
    padding: 0 .5rem;
    font-weight: 600;
    font-size: 0.9375rem;
    color: white;
    width: auto;
    height: 1.6875rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.monthName {
  &:first-letter {
    text-transform: capitalize;
  }
}
