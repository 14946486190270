html {
  height: 100%;
  font-size: 0.5rem;
}

body {
  min-height: 100%;
  min-width: 375px;
  font-size: 100%;
  background: #f7f9fa;
  font-family: var(--main-font);
  /* font-weight: $global-weight-normal;
  line-height: $global-lineheight; */
  font-weight: 300;
  line-height: 1.3;

  color: var(--color-font-main);
  position: relative;

  &.modal-open {
    overflow: visible;
  }
}

#root{
  width: 100%;
  height: 100%;
}

*:not(svg) {
  //scrollbar-gutter: stable;
}

* {
  scrollbar-color: var(--color-background-primary) #e0e0e0;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  transition: scrollbar-color 0.3s ease-out;
  box-sizing: border-box;
  // background-clip: content-box;
  // &:hover {
  //   scrollbar-color: var(--color-border-field2);
  // }
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 10rem;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-background-primary);
  border-radius: 10rem;
  background-clip: content-box;
}

.content {
  transition: margin 400ms cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: margin;
  max-width: 1550px;
  position: relative;
  overflow: auto;
}

.container {
  width: 100%;
  max-width: none;
  padding: rem(0 20);

  @media (max-width: 540px) {
    padding: 0 10px;
  }

  &.settings {
    padding: rem(0 50);
  }
}

@-webkit-viewport {
  width: device-width
}

@-moz-viewport {
  width: device-width
}

@-ms-viewport {
  width: device-width
}

@-o-viewport {
  width: device-width
}

@viewport {
  width: device-width
}

h6 {
  //font-size: $h6-font-size;
  font-weight: 300;
  margin: 0 0 1.5rem;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

main,
.clearfix {
  &:after {
    display: table;
    line-height: 0;
    clear: both;
    content: "";
  }
}

*:focus {
  outline: none !important;
}

a,
button {
  cursor: pointer;
  transition: all 250ms;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-col-6 {
  flex-basis: 50%;

  &:nth-child(2n) {
    margin-left: 1rem;
  }
}

textarea {
  max-width: 100%;
  /* min-height: $min-height-textarea;
  max-height: $max-height-textarea; */
  resize: none;
  padding-top: rem(5);
}

.fa-check,
.fa-check-circle {
  //color: $icon-check-color;
}

.fa-ban,
.fa-minus-circle,
.fa-times-circle {
  //color: $icon-error-color;
}

.fa-question-circle {
  //color: $design-gray;
}

.icon-default {
 // color: $bright-blue;
//
  &.fa-star {
    //color: $design-yellow;
  }

  &_gray {
    //color: $gray-200;
  }
}

.icon-purple {
  //color: $purple;
}

.anticon {
  svg {
    display: none;
  }
}

.ant-tooltip-inner {
  text-align: center;
}

.icon-gray {
  //color: $medium-gray;
  transition: color 0.25s;

  &:hover {
    //color: $gray-400;
  }
}

i {
  transition: color 250ms;
}

// .w-100 {
//   margin-bottom: rem(16);
// }

// .anticon {
//   display: none;
// }

.sales-statistics {
  .w-100 {
    margin-bottom: 0;
  }
}

.page-wrapper {
  position: relative;
  background-color: #f7f9fa;
}

.fa-phone {
  transform: scale(-1, 1);
}

.inventory-author {
  //color: $main-color;
  font-size: 1.125rem;
  font-weight: 500;
}

.textAreaOverflow {
  textarea {
    overflow: auto !important;
  }
}

.text-center {
  .count-select {
    margin: 0 auto;
  }
}

.gray {
  //color: $gray-400;
}

.text-red {
  //color: $red;
}

.Select.is-open {
  z-index: 3;
}

.font-bold {
  font-weight: 500;
}

.selected-provider {
  font-size: rem(22);
  font-weight: 300;
  padding-bottom: rem(30);
  margin-bottom: rem(28);
  border-bottom: 1px solid #e6ebf0;
}

.max-width-medium {
  max-width: rem(980);
}

.loading-spinner-container {
  padding: 0 rem(30, 14);
}

label {
  font: normal normal 400 1rem var(--main-font) !important;
  color: #666666;
}