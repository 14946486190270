@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

@media (max-width: 2501px) {
    div.container {
      max-width: 1550px !important;
    }
  }
  
  @media (max-width: 1920px) {
    div.container {
      max-width: 1200px !important;
    }
  }
  
  @media (max-width: 1599px) {
    div.container {
      max-width: 1000px !important;
    }
  }

  .goodIcon {
    position: absolute !important;
    right: 15px !important;
    top: 54%;
    color: green;
    width: 20px;
    height: 20px;
  }
  
  .badIcon {
    position: absolute !important;
    right: 15px !important;
    top: 54%;
    color: #4FB2ED;
    width: 20px;
    height: 20px;
  }

label {
    color:#000;
    font-size: 16px;
    font-weight: 400;
}

.oldPassword {
  display: none;
}

.oldPassword.active {
  display: block;
}

.buttonDisplay {
  display: none;
}

.buttonDisplay.addDisplay {
  display: block;
}