.container {
  .big {
    display: flex;
    align-items: center;
    max-width: 42.4rem;
    border-radius: 1.19rem;
    background: #FFF;
    opacity: 0.9;
    box-shadow: 0 .19rem .38rem rgba(0, 0, 0, 0.08);
    cursor: default;
    padding-right: 1rem;
  }
  .box {
    display: flex;
    margin: 0 auto;
    align-items: center;
    opacity: 1;
    color: #000000;

    .item {
      margin: 0.6rem 0 0.5rem 0.778rem;
      font: normal normal normal 0.7rem/1rem Montserrat;
    }

    .devider {
      margin: 0.66rem 0 0.61rem 0.972rem;
      content: ' ';
      height: 0.83rem;
      width: .19rem;
      border-radius: .25rem;
    }
  }

}


.doned{
  background-image: repeating-linear-gradient(135deg, #D5C9DF, #D5C9DF 1.5px, transparent 1.5px, transparent 2.8px);
  background-color: white;
}

.confirmed{
  background-image: repeating-linear-gradient(135deg, #4FB1EB, #4FB1EB 1.2px, transparent 1.2px, transparent 4.46px);
  background-color: #D5C9DF;
}