@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNext-Regular-08.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNext-Medium-06.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNext-DemiBold-03.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "AvenirNextCyr";
  src: url("../fonts/AvenirNext-Bold-01.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

:root {
  --main-font: AvenirNextCyr;
  --title-font: Montserrat;
}