.container {
  display: flex;
  align-items: center;
  padding: 0.2rem 0.6rem;
  height: 1.875rem;
  border-radius: 1rem;
  background: #e6f4fc;
  cursor: pointer;
  margin-left: 0.5rem;
}

.wrapper {
  position: relative;
  z-index: 3;
}

.icon {
  position: relative;
  margin-left: 0.425rem;
  margin-right: 0.625rem;
}

.counter {
  position: absolute;
  background-color: #4fb2ed;
  color: #fff;
  top: 0;
  right: 0;
  transform: translateX(50%) translateY(-50%);
  font-size: 0.75rem;
  line-height: 1.125rem;
  border-radius: 0.6rem;
  padding: 0 0.5rem;
}

.disabled {
  opacity: 0.5;
}

.popup {
  padding: 0.25rem;
  position: absolute;
  top: 1.875rem;
  min-width: 10rem;
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #C3C3C3;
}

.file {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.2rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.arrow {
  flex-shrink: 0;
}

@media screen and (max-width: 1441px) {
  .container {
    font-size: 11px !important;
    height: 1.675rem;

    & .icon {
      margin-left: 0.3rem;
      margin-right: 0.3rem;

      & svg {
        height: 17px;
      }
    }
  }

  .arrow {
    width: 0.7rem;
    display: none;
  }

  .counter {
    font-size: 10px;
    padding: 0 0.3rem;
  }
}
