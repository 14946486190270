.inputFilesList__file {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  width: 6rem;
  font-family: var(--main-font);
}

.inputFilesList__file_icon {
  position: absolute;
  top: -7px;
  right: 0;
  cursor: pointer;
}

.inputFilesList__fileIcon {
  width: 66px;
  height: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  text-transform: uppercase;
  border-radius: 0.5rem;
  background-color: #c5fad3;
}

.inputFilesList__fileName {
  font: normal normal 1rem/1.25rem var(--main-font);
  color: #707070;
  text-align: center;
  word-break: break-all;
  padding: 0 0.5rem;
}

.loading {
  opacity: 0.5;
}