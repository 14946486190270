.table {
  position: relative;
  margin-left: -1.8rem;
  margin-right: -1.8rem;
}

.bordered {
  border-bottom: 2px solid #4FB1EB;
  padding-bottom: 1rem;
  margin-top: 0.75rem;
}

.minHeight {
  min-height: calc((100vh - 528.812px) - 2.2rem);
}

.wrapper {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.subtitle {
  margin-top: 1rem;
  margin-bottom: 0.625rem;
}

.header {
  border-top: 1px solid #D8D8D8;
}