.weeksLine {
  position: sticky;
  top: 0;
  margin-left: 35%;
  width: 100%;
  z-index: 97;
}

.weekCaptionBlock{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  position: absolute;
  top: 0;
  height: 2.81rem;
  background-color: #F4F4F4;
  border-bottom: 1px solid rgba(177, 177, 177, 1);
}

.weekInterval{
  color: #4FB1EB;
}

.currentLine {
  position: absolute;
  width: 0.1875rem;
  min-height: calc(100vh - 5.62rem);
  top: 2.81rem;
  background-color: #707070;
  z-index: 0;
}

.currentWeekDay {
  position: absolute;
  height: 100%;
  width: 1.6875rem;
  top: 2.5rem;
  z-index: 1;
  transform: translateX(12.5%);
  span{
    background: #4FB2ED;
    border-radius: 100%;
    font-weight: 600;
    font-size: .9375rem;
    color: white;
    width: 1.6875rem;
    height: 1.6875rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
