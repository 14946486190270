.input {
  border: 1px solid #c4c4c4;
}

label {
  margin-bottom: 0.35rem;
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color-border-field18);
  border-radius: 0.5rem;
  font: 1rem AvenirNextCyr;
  padding: .2rem 1rem;
  cursor: pointer;
  position: relative;
  width: auto;
  background-color: white;
  &.isOpen {
    border-color: #4fb2ed;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem 0.75rem 0 0;
    z-index: 20;
  }

  &.disabled {
    color: #a0a0a0;
  }
}

.errorMessage {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #ff0558;
}

.filterInput {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border: none;

  &::placeholder {
    color: darken(#ececec, 30%);
  }
}

.option {
  border-radius: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  column-gap: 0.53rem;
  padding: 0.5rem;
  align-items: center;
  &:hover {
    background: #4fb2ed;
    color: #fff;
  }
}
.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrow {
  transition: all 0.2s ease;
  width: 1rem;
}

.arrowReverse {
  transform: rotate(-180deg);
}

.optionsBlock {
  border-radius: 0 0 0.75rem 0.75rem;
  position: absolute;
  background: #fff;
  z-index: 20;
  transition: all 0.3s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  height: 0;
  width: calc(100% + 2px);
  top: 100%;
  left: -1px;
  &.isOpen {
    height: auto;
    border-right: 1px solid #4fb2ed;
    border-left: 1px solid #4fb2ed;
    border-bottom: 1px solid #4fb2ed;
    border-top: 1px solid #dddddd;
  }
}

.isOpen {
  display: block;
}

.bubbles {
  display: flex;
  flex-wrap: wrap;
  gap: 0.53rem;
  margin-bottom: .35rem;
}

.bubble {
  border-radius: .8rem;
  color: white;
  background-color: var(--color-background-primary);
  padding: 0.5rem 0.66rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.4rem;
}

.removeIcon,
.clearFilterIcon {
  cursor: pointer;
  width: 0.6875rem;
  height: 0.6875rem;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 1px solid #a0a0a0;
  border-radius: 0.2rem;
  padding: 0.15rem;
  width: 1.1875rem;
  height: 1.1875rem;
  cursor: pointer;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.9rem;
  position: absolute;
  right: .9rem;
  top: 0;
  transform: translateY(50%);
}
