
.checkItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
}

.content {
  color: #000;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--main-font);
  font-size: 1rem;

  .inputSecondary {
    margin: 0 1rem;
    padding: 0;

    .addFileButton {
      display: flex;
      align-items: center;
      height: 110%;
      width: 100%;
      padding: 0 1.5rem;
      margin-bottom: 0;
      cursor: pointer;
    }
  }
  .fileName {
    width: 10rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.disabled {
  pointer-events: none;
}