$error-text: #ff0000;
$input-placeholder-color-auth: #c4c4c4;
$gray-300: #5D6164;
$background-page-color: #f7f9fa;
$main-color: #00B4F2;
$gray-200: #B1B6BB;
$order-gray: #9b9b9b;
$main-color: #00B4F2;
$global-border: 1px solid $main-color;

@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@function rem($values, $base: null) {
  $rem-values: ();
  $count: length($values);

  // If no base is defined, defer to the global font size
  @if $base == null {
    $base: 16px;
  }

  // If the base font size is a %, then multiply it by 14px
  // This is because 100% font size = 14px in most all browsers
  @if unit($base) == "%" {
    $base: ($base / 100%) * 14px;
  }

  // Using rem as base allows correct scaling
  @if unit($base) == "rem" {
    $base: strip-unit($base) * 14px;
  }

  @if $count == 1 {
    @return -zf-to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

[data-page="authorization"] {
  .ant-switch {
    background: #fff;
    box-shadow: inset rem(1 1 2 0) 0 rgb(0, 0, 0, 0.5);
    &:before {
      box-shadow: inset rem(2 2 3) 0 rgb(144, 144, 144);
    }
    &:after {
      box-shadow: inset rem(2 2 3) 0 rgb(144, 144, 144);
    }
    &-checked {
      background-color: #77c5f9;
      &:before,
      &:after {
        box-shadow: inset rem(2 2 3) 0 rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.authorization {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;

  .ant-tabs-tab {
    background: transparent;
    font-size: 1rem;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid transparent;
    border-radius: 0;
    padding: 0 0 0.2rem;
    min-width: 0;
    cursor: pointer;

    &:not(:last-child) {
      border-right: none;
    }
    &:hover {
      background: none;
      color: #000;
      border-color: currentColor;
    }
    &.ant-tabs-tab-active {
      background: none;
      border-color: currentColor;
      color: #000;
    }
  }
  .ant-tabs-tab {
    padding: 0.8125rem 1rem;
    &:not(:last-child) {
      margin-right: 0;
    }
  }

  .ant-tabs-nav-container-scrolling {
    border: none;
    border-radius: 0;
    overflow: visible;
    padding: 0;
  }

  .ant-tabs-nav {
    border-bottom: none;
    max-width: 15rem;
    margin: 0 auto;
    padding: 0;

    .ant-tabs-nav-container {
      margin-bottom: 0;
    }
    .ant-tabs-tab-arrow-show {
      display: none;
    }
  }

  .ant-tabs-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    &:after,
    &:before {
      display: none;
    }
    & > div:not([class]) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .ant-radio-wrapper {
    margin-bottom: 0.5rem;
    &:not(:last-child) {
      margin-bottom: 1.625rem;
    }
  }
}

.authorization__form-container {
  margin-top: -1px;
}

.authorization__container {
  width: 100%;
}

.authorization__header {
  padding: 7.14286rem 0 10rem;
  width: 100%;
}

.authorization__logo {
  max-width: 6.14286rem;
  margin: 0 auto;
}

.authorization__panel {
  width: 100%;
}

.authorization__info {
  opacity: 0.8;
  background-color: #fff;
  font-weight: 600;
  color: #000;
  font-size: 2.5rem;
  position: absolute;
  max-width: 37.5rem;
  padding: 2.8125rem 3rem;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.authorization__form {
  max-width: 17rem;
  margin: 0 auto;
  .form__title {
    font-size: 0.98rem;
    text-align: center;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .help-text {
    &.error {
      padding-top: 0;
      color: $error-text;
      font: {
        size: 0.75rem;
        weight: 300;
      }
    }
  }
  .ant-radio-group {
    display: flex;
    justify-content: space-between;
    .ant-radio-wrapper {
      margin: 0.5rem 0 1.5rem;
    }
  }

  span.ant-radio + * {
    padding-left: 0.4rem;
    padding-right: 1rem;
  }
}

.authorization__form-item {
  position: relative;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
  &_small-margin {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .form__label {
    margin-bottom: 0;
  }
}


.authorization__label {
  display: block;
  font-size: 0.875rem;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  &.error {
    .authorization__input {
      color: $error-text;
      border-bottom-color: $error-text;
      @include input-placeholder {
        color: $error-text;
      }
    }
    .authorization__input-icon {
      &_mail {
        background-image: url("../images/authorization/mail-error.svg");
      }
      &_password {
        background-image: url("../images/authorization/password-error.svg");
      }
    }
  }
}

.authorization__input {
  background: none;
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
  padding: 0 0.3rem 0.3rem 1.5rem;
  margin-bottom: 0.35rem;
  font-weight: 400;
  font-size: 0.875rem;
  color: #000;
  height: 1.5rem;
  @include input-placeholder {
    color: $input-placeholder-color-auth;
    opacity: 1;
    transition: all 250ms;
  }
  &:focus {
    border: none;
    border-bottom: 1px solid #000;
    @include input-placeholder {
      opacity: 1;
      color: $input-placeholder-color-auth;
    }
  }
}

.authorization__input-icon {
  position: absolute;
  left: 0;
  bottom: 0.5625rem;
  &_mail {
    background: url("../images/authorization/mail.svg") no-repeat;
    background-size: contain;
    width: 16px;
    height: 13px;
  }
  &_password {
    background: url("../images/authorization/password.svg") no-repeat;
    background-size: contain;
    width: 14px;
    height: 20px;
  }
}

.authorization__text {
  border-bottom: 1px solid #000;
  padding-bottom: 0.5rem;
  margin: 2rem 0;
  font-weight: 400;
  font-size: 0.875rem;
  color: #000;
}

.authorization__confirm {
  font-size: 12px;
  color: #c4c4c4;
}

.authorization__confirm-panel {
  max-width: 19.375rem;
  margin: 0 auto;
  text-align: center;
}

.authorization__img {
  max-width: 7.75rem;
  margin: 3.8125rem auto 2.375rem;
}

.authorization__main-error {
  margin-bottom: 1.2rem;
}

.authorization__buttons {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn {
    display: block;
    font-size: 1rem;
    max-width: 11.25rem;
    width: 100%;
    margin: 0 auto 2rem;
  }
}

.authorization__link {
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 400;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #000;
  transition: border-bottom-color 250ms;
}

.authorization__controls {
  padding-top: 3.71429rem;
  padding-bottom: 6vh;
  min-height: 10vh;

  min-height: 10vh;
  &_registration {
    padding-bottom: 2.75rem;
  }
}

@media (max-width: 1440px) and (max-height: 768px) {
  .authorization__confirm {
    font-size: 10px;
  }
}

@media (max-width: 1200px) {
  .authorization__confirm {
    font-size: 9px;
  }
}

@media (min-width: 768px) {
  .authorization__link {
    &:hover,
    &:active,
    &:focus {
      border-bottom-color: transparent;
    }
  }
}

@media (max-width: 768px) {
  .authorization {
    .ant-tabs {
      .ant-tabs-bar {
        max-width: rem(230, 12);
        .ant-tabs-tab {
          font-size: rem(14, 12);
        }
      }
    }
    .ant-radio-wrapper {
      font-size: rem(14, 12);
    }
  }
  .authorization__form {
    max-width: rem(230, 12);
    margin-bottom: rem(40);
    .help-text {
      // bottom: rem(-25);
      &.error {
        font-size: rem(10, 12);
      }
    }
  }
  .authorization__input {
    font-size: rem(14, 12);
    padding: rem(0 5 5 25, 12);
    height: rem(30, 12);
    &-icon {
      bottom: rem(15);
    }
  }
  .authorization__text {
    font-size: rem(12, 12);
  }
  .authorization__link {
    font-size: rem(12, 12);
  }
  .authorization__confirm {
    font-size: rem(10, 12);
  }
  .authorization__btn {
    max-width: rem(180, 12);
    font-size: rem(16, 12);
  }
  .authorization__form-item {
    &:not(:last-child) {
      margin-bottom: rem(35, 12);
    }
  }
  .authorization__password-recovery {
    max-width: rem(270, 12);
  }
  .authorization__controls {
    min-height: rem(260, 12);
  }
  .authorization__confirm {
    font-size: 10px;
  }
}

@media (max-width: 375px) {
  .authorization {
    .ant-radio-wrapper {
      &:not(:last-child) {
        margin-bottom: rem(15);
      }
    }
    // .ant-radio-group {
    //   margin-bottom: rem(15);
    // }
  }
  .authorization__header {
    padding: {
      top: rem(35);
      bottom: rem(75);
    }
  }
  .authorization__controls {
    min-height: 10vh;
    // min-height: rem(210, 12);
    padding: {
      top: rem(30);
      // bottom: rem(50);
      bottom: 6vh;
    }
    &_registration {
      padding-bottom: rem(22);
    }
  }
  .authorization__btn {
    margin-bottom: rem(15);
  }
  .authorization__img {
    margin: {
      top: rem(20);
      bottom: rem(30);
    }
  }
  .authorization__form {
    margin-bottom: rem(10);
  }
  // .authorization__form-item {
  //   &_small-margin {
  //     &:not(:last-child) {
  //       margin-bottom: rem(25);
  //     }
  //   }
  // }
}

@media (max-width: 320px) {
  .authorization__header {
    padding: {
      top: rem(35);
      bottom: rem(55);
    }
  }
}


.form__input {
  display: block;
  max-width: 100%;
  padding: 0.25rem 1.25rem 0 0;
  border: none;
  border-bottom: 1px solid $gray-200;
  border-radius: 0;
  transition: border-color 300ms;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:focus {
    text-overflow: clip;
    & + .form__label-text {
      font-size: 0.875rem;
      line-height: 1.67;
      top: -0.5rem;
    }
    @include input-placeholder {
      opacity: 0;
    }
  }
  @include input-placeholder {
    color: $order-gray;
    opacity: 1;
    transition: opacity 250ms;
  }

  &.error {
    border-bottom-color: red;
  }

  &_solid-border {
    border-image: none;
    border: none;
    border-bottom: 1px solid $gray-200;
    padding-left: 1.25rem;
    &:focus {
      border-image: none;
      border: none;
      border-bottom: 1px solid $gray-200;
    }
    & + .form__label-text {
      color: #000;
      top: 0.125rem;
      padding-left: 1.25rem;
      font-weight: 300;
    }
  }
}