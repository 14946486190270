.flexInput {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  min-height: 4.375rem;
  position: relative;
}

.border {
  border: 1px solid var(--color-border-field7);
}

.inputForm {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexAction {
  display: flex;
  justify-content: space-between;
  padding: 20px 25px 0 20px;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  margin-bottom: 35px;
}

.text {
  font-size: 1.125rem;
  font-weight: 400;
  color: black;
}
