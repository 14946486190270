.chatContainer {
  position: fixed !important;
  z-index: 999;
  bottom: 2rem;
  right: 2rem;
}

.dragContainer{
  pointer-events: none;
  width: 30rem;
  height: 40rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;
}

.openChatBlock{
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-left: 0.3rem;
  &:active{
    cursor: grabbing;
  }
  .openChatText {
    width: 10.5rem;
    padding-left: 1.8rem;
    padding-right: 1rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: end;
    white-space: nowrap;
    border: #00b4f2 1px solid;
    border-radius: 0.5rem;
    background-color: white;
  }
}

.hiddenChat{
  position: absolute;
  left: -1.5rem;
  top: -0.25rem;
  width: 3rem;
  height: 3rem;
  background-color: #00b4f2;
  border-radius: 100%;
  background-image: url("./../../../../images/icons/speech-bubble.svg");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.unReadValue{
  position: absolute;
  padding: 0.2rem 0.5rem;
  min-width: 1.7rem;
  border-radius: 1rem;
  background-color: #ff0101;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -0.6rem;
  transform: translateX(2rem);
}
.tabsCounter{
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.1rem;
  color: #696969;
  font-weight: 400;
  border-radius: 1rem;
  border: 1px solid;
  border-color: #E1E8EE;
  background-color: white;
  transform: translateX(30%) translateY(-30%);
  line-height: 1.25rem;
  font-size: 1.25rem;
}
.tabsCounterCircle{
  border-radius: 1rem;
  padding: 0.1rem 0.4rem;
  font-weight: 400;
}
.tabsCounterCircle_Red{
  background-color: #ff0101;
  color: white;
  font-weight: bold;
}

.loadingPlate{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.loadMorePlate{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
  flex-shrink: 0;
}

.loadMoreButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
  flex-shrink: 0;
}

.emptyMessages{
  width: 100%;
  text-align: center;
  color: #737373;
  font-weight: 500;
}

.belowMessages{
  position: absolute;
  transform: translateY(-100%);
  top: 0;
  left: 1rem;
  padding: 0.7rem;
  background-color: #00b4f2;
  color: white;
  border-radius: 2rem;
  box-shadow: 0 0 5px 1px #c3c3c3;
  font-weight: bold;
  align-self: flex-start;
  cursor: pointer;
}

.usersSuggestionsContainer{
  width: 100%;
  position: absolute;
  transform: translateY(-100%);
  top: 0;
  left: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}

.usersSuggestions{
  border-radius: 0.5rem;
  border: 1px solid #cbd1d7;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 30rem;
}

.userName{
  font-weight: 600;
}

.userRole{
  color: #00b4f2;
}

.usersItems{
  padding: 0.5rem;
  cursor: pointer;
  background-color: white;
  &:hover{
    background-color: darken(white, 3%);
  }
  &:not(:last-child){
    border-bottom: 1px solid #cbd1d7;
  }
}

.chatWindow{
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
}

.dateContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .dateText{
    margin-bottom: 1rem;
    font-weight: bold;
    color: #737373;
  }
}

.handleContent{
  padding: 2rem 1rem 1rem;
  width: 100%;
  background-color: #00b4f2;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  position: relative;
  cursor: move;
  .title{
    font-size: 1.25rem;
  }
  .subTitle{
    color: lighten(#00b4f2, 45%);
  }
  .minimizeButton{
    height: 1.25rem;
    width: 1.5rem;
    border-bottom: 3px white solid;
    position: absolute;
    right: 1rem;
    top: 0;
    cursor: pointer;
    box-sizing: content-box;
  }
  .cancelButton{
    position: absolute;
    right: 1rem;
    top: 1rem;
    button{
      color: white;
    }
  }
}

.messagesContainer{
  width: 100%;
  height: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  flex-grow: 2;
  position: relative;
}

.controls{
  display: flex;
  align-items: center;
  padding: 1rem;
  flex-shrink: 0;
  position: relative;
  textarea{
    min-height: initial;
    max-height: none;
  }
  img{
    width: 2rem;
    margin-left: 1rem;
    opacity: 0.8;
    transition: opacity 0.1s ease-in-out;
    cursor: pointer;
    &:hover{
      opacity: 1;
    }
  }
}

@media (max-width: 800px) {
  .dragContainer{
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
  }
  .chatWindow{
    border-radius: 0;
  }
}
