a {
    color: #000;
    &:hover {
        color: #000;
        text-decoration: none;
    }
}

button {
    background: none;
    border: none;
    outline: none;
}

[type='email'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='time'], [type='url'], [type='week'], [type='area'], textarea, input {
    border: 1px solid #00B4F2;
    border-radius: 0.75rem;
    background-color: transparent;
    transition: background-color 0.25s, border-color 0.25s ease-in-out;
    height: 2.14286rem;
    width: 100%;
    font-size: 1rem;
    color: #4a4a4a;
    padding: 0.5rem;
    -webkit-appearance: none;
}

svg {
    vertical-align: middle;
}

label {
    display: block;
    margin-bottom: 0.35rem;
}

.ant-message {
    z-index: 999999999999;
}