.form {
  display: flex;

  label {
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: black;
    font-family: var(--title-font) !important;
  }
}

.fieldInput {
  width: auto !important;
}

.pair {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}

.label {
}

.calendarOptions {
  left: -20%;
}

textarea.fieldInput {
  height: auto;
}

.options {
  width: calc(100% + 2px) !important;
  top: 100% !important;
}

.fields {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 1.2rem;
  width: 100%;
}

.isVisible {
  display: flex;
}

.range {
  flex: 1;
}

.errorMessage{
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #FF0558;
}

.isHidden{
  display: none;
}