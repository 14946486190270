.template {
  overflow-y: hidden !important;
}

.container{
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content {
  display: flex;
  flex: 1;
}

.controls {
  display: flex;
  height: auto;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
  column-gap: 0.5rem;
  padding: 1.88rem 4.88rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 98;
  background: #fff;
}

.datePicker{
  height: 2.813rem;
  & > div:first-child {
    width: max-content;
  }
}

.dateBlock {
  display: flex;
  background-color: #f4f4f4;
  padding: 0 0.4rem;
  border-radius: .625rem;
}

.calendar {
  position: relative;
  width: 100%;
  scroll-behavior: auto;
  overflow-x: scroll;
  min-height: calc(100vh - 9.39rem);
  max-height: calc(100vh - 9.39rem);
  padding-bottom: 3rem;
  overflow-y: scroll;
}

.dayWeekend {
  top: 2.25rem;
  position: absolute;
  height: 100%;
  background-color: #ebebeb;
}

.dayWork {
  position: absolute;
  height: 100%;
  top: 2.25rem;
  border-right: 1px dashed #dedede;
}

.legendaWrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.legenda {
  position: fixed;
  bottom: 1.56rem;
  display: flex;
  justify-content: center;
  z-index: 98;
  margin-left: 25%;
}

.diagramActionsWrapper {
  position: fixed;
  top: 13rem;
  right: 2.2rem;
  display: flex;
  justify-content: center;
  z-index: 98;
}

@media screen and (min-width: 1920px) {
  .treeHead{
    width: calc(35% - .5rem);
  }
  .tab{
    width: 9.8rem;
  }
}

.weeksLineControls{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 100%;
  width: calc(65% - 1rem);
  height: 2.81rem;
  right: .5rem;
}

.weeksLineButton{
  width: 1.875rem;
  height: 1.875rem;
  padding: .475rem;
  background-color: #DEDEDE;
  &:first-child{
    padding-left: .416rem;;
  }
  &:last-child{
    padding-right: .416rem;;
  }
}

.runningLine{
  background-color: rgba(216, 241, 255, .5);
  z-index: 0;
  height: 3rem;
  position: absolute;
}

.weeksLine, .daysLine {
  position: absolute;
  top: 0;
  margin-left: 35%;
  width: auto;
  z-index: 0;
  min-height: calc(100% + 3rem);
}

.viewTabs > div{
  padding: .3125rem .75rem;
  width: min-content;
}

.weekBackground {
  position: absolute;
  top: 2.81rem;
  width: auto;
  min-height: 100%;
}

.currentLine, .currentDay {
  position: absolute;
  width: 0.1875rem;
  min-height: 100%;
  background-color: #707070;
  z-index: 0;
}

.currentLine{
  top: 2.81rem;
  transform: translateX(50%)
}

.currentDay {
  top: 2.25rem;
  transform: translateX(-50%)
}