.container {
  min-width: min-content;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;

  .content {
    padding: 0 1.8rem;
    overflow-y: scroll;
    // height: calc(100% - 78px);
    flex: 1;
  }

  .fieldCalendar {
    width: 100%;
  }
  
  .fieldRow {
    display: flex;

    .fieldBlock {
      width: 50%;
    }
  }
  .fieldValue {
    color: #707070;
    opacity: 0.8;
    padding: 0;
    font: normal normal normal 0.9em var(--main-font);
  }
  .fieldTitle {
    font: normal normal bold 0.9em var(--main-font);
    color: #000000;
    margin: 1.5rem 0 0.5rem 0;
    white-space: nowrap;
    display: flex;
    align-items: center;
    // font: 0.9em var(--main-font);
    // font-weight: bold;
  }
  .plusIconBlock {
    display: flex;
    align-items: center;
  }
  .titleBlock {
    padding: 1rem 2rem 0 1rem;

    .title {
      font: normal normal bold 1.3em var(--main-font);
      color: #000000;
    }
    .subTitle {
      color: #707070;
      padding: 0.5rem 0;
      opacity: 0.8;
      font: normal normal normal 0.9em var(--main-font);
    }
  }
  .blockTitle {
    font: normal normal bold 14px var(--main-font);
    color: #000000;  
  }
}
.sliderClassName {
  width: 34rem;

  & > div {
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
}
.actionBlock {
  border-top: 1px solid #BCBCBC;
  padding: 10px 31px;
  // position: absolute;
  width: 100%;
  bottom: 0;
  text-align: right;
  background-color: #fff;
  border-radius: 0 0 0 20px;
  display: flex;
  justify-content: center;
}

.icon {
  width: 1.5rem;
  cursor: pointer;
  padding: 1rem 0;
  margin-right: 1rem;
}