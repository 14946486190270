.tree {
  width: 35%;
  position: sticky;
  left: 0;
  z-index: 97;
  background-color: white;
  min-height: calc(100vh - 3.81rem);
  &:before {
    content: "";
    display: flex;
    background-color: #fff;
    width: 100%;
    height: 2.81rem;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
  }
}
