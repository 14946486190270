@import "src/scss/dimensions";

.container {
  border-bottom: 2px solid var(--color-border-field2);
  padding: 1.23rem 0 1.87rem 0;

  .count {
    position: absolute;
    top: -0.4rem;
    left: 1.1rem;
    background-color: var(--color-background-primary);
    font: normal normal 400 0.87rem var(--title-font);
    color: var(--color-font-hover);
    border-radius: 1rem;
    min-width: 1.57rem;
    padding: 0.05rem 0;
    text-align: center;
  }

  .productsHeaderTableAction {
    display: flex;
    align-items: center;
    font: normal normal 400 1.12rem var(--title-font);
    padding: 0 2.56rem;
    height: 2.5rem;
    justify-content: space-between;

    .leftSide {
      display: flex;
      align-items: center;
    }

    .title {
      width: 13.5rem;
    }

    .icon {
      margin-right: 2.7rem;
      cursor: pointer;
    }

    .chooseProviderButton {
      width: min-content;
      margin-left: 2rem;
    }
  }

  .monitoringSwitcherContainer {
    justify-content: flex-end;
    padding-top: 1rem;
    margin-bottom: -1rem;
  }

  .headerTable {
    display: flex;
    font-weight: 500;
    margin-top: 1rem;
    padding-left: 2.56rem;
    height: 1.8rem;

    & > * {
      white-space: nowrap;
    }
  }

  .headerTable .title,
  .productRow .title {
    width: 30%;
  }

  .section {
    background-color: var(--color-background-field4);
    height: 2.9rem;
    display: flex;
    align-items: center;
    padding: 0 2.56rem;
    font: normal normal 400 1.12rem var(--title-font);
    border-bottom: 1px solid var(--color-border-field1);
  }

  .productRow {
    display: flex;
    align-items: center;
    min-height: 3.68rem;
    font: normal normal 400 1.12rem var(--title-font);
    padding-left: 2.56rem;
    border-bottom: 1px solid var(--color-border-field1);

    .measureCol {
      text-align: center;
    }

    .providerBlock {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      padding: 1rem;
    }

    .addProviderButton {
      border-color: var(--color-border-primary);
    }

    .providerItem {
      background: #ddf0fc;
      width: 100%;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      column-gap: .5rem;

      .providerName {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .crossIcon {
        cursor: pointer;
      }
    }

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 2rem;
    }

    .actionBlock {
      display: flex;
      align-items: center;

      .action, .separator {
        flex: 1;
      }

      .paperclipIcon {
        position: relative;
        cursor: pointer;

        .paperclipIconCount {
          @extend .count;
          top: -0.2em;
          left: 0.5rem;
        }
      }

      .crossIcon {
        cursor: pointer;
      }
    }

    .input {
      width: 7.813rem;
    }

    .inputInner {
      border: none;
      background-color: #efefef;
      border-radius: 0.5rem;
      color: var(--color-font-main);
      opacity: 1;
    }

    .calendar, .inputInner {
      height: 2.18rem;
    }

    .calendar {
      border: none;
      background-color: #efefef;
      width: 8.5rem;

      img {
        margin: 0;
      }
    }

    .disabled {
      background: none;
    }
  }

  .emptyBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .title {
      font: normal normal 300 1.25rem var(--title-font);
      margin-top: 1.68rem;
      margin-bottom: 3rem;
    }
  }

  .argeementBlock {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 1.65rem 2.53rem;
  }

  .productsTable {
    .titleCol {
      width: 43%;
    }

    .matchingCol {
      width: 4%;
      display: flex;
      justify-content: flex-end;
    }

    .measureCol {
      width: 5%;
    }

    .orderCol {
      display: flex;
      flex-direction: column;

      & > * {
        margin-bottom: 0.25rem;
      }
    }

    .requestCountCol,
    .dateCol,
    .orderCol {
      width: 9%;
    }

    .planFactDiffCol {
      width: 10%;
    }

    .providerCol {
      width: 15%;
    }

    .actionsCol {
      width: 13%;
    }

    .titleCol,
    .measureCol,
    .matchingCol,
    .requestCountCol,
    .dateCol,
    .providerCol,
    .actionsCol,
    .checkboxCol,
    .orderCol,
    .planFactDiffCol {
      padding-right: 1rem;
    }

    @include breakpoint-to($laptop) {
      .titleCol {
        width: 33%;
      }

      .matchingCol {
        width: 5%;
      }

      .measureCol {
        width: 7%;
      }

      .requestCountCol,
      .dateCol {
        width: 10%;
      }

      .actionsCol {
        width: 17%;
      }
    }
  }

  .productsTable.isDraft {
    .titleCol {
      width: 56%;
      margin-right: 0.625rem;
    }

    .matchingCol {
      width: 1%;
      display: flex;
      justify-content: flex-end;
    }

    .measureCol {
      width: 5%;
    }

    .requestCountCol {
      width: 12%;
    }

    .dateCol {
      width: 13%;
    }

    @include breakpoint-to($laptop) {
      .titleCol {
        width: 50%;
      }

      .measureCol {
        width: 7%;
      }
    }
  }

  .productsTable.withOrders {
    .titleCol {
      width: 47%;
    }
  }

  .productsTable.viewModeMonitoring {
    .titleCol {
      width: 57%;

      @include breakpoint-to($laptop) {
        width: 49%;
      }
    }

    .planFactDiffCol {
      width: 12%;
    }
  }
}

.comment {
  position: relative;
  cursor: pointer;

  .commentCount {
    position: absolute;
    top: -8px;
    left: 14px;
    border-radius: 12px;
    background-color: #4FB2ED;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 500;
    width: 1.5625rem;
    height: 1.0625rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
