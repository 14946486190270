.container {
    height: 2.8125rem;
    display: flex;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 3;
    justify-content: space-between;

    &:before {
        content: "";
        position: absolute;
        background-color: #fff;
        height: 1.88rem;
        width: calc(100% + 2rem);
        left: -2rem;
        height: calc(100% + 1.88rem);
        top: -1.88rem;
        z-index: -1;
    }
}
