.btn {
    display: flex;
    align-items: center;

    & svg {
        width: 2rem;
        height: 2rem;
    }
}

.disabled {
    opacity: 0.7;
}

.text {
    margin-left: 0.5rem;
}