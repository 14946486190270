.tableRow {
  background: #F4F4F4;
  border-radius: 0.5rem;
  font: normal normal 400 1rem var(--title-font);
  color: #000;
  margin-bottom: 0.6rem;
  border: 1px solid #F4F4F4;
  position: relative;

  &.active {
    background: #fff;
    border: 1px solid #4FB2ED;

    .header {
      border-bottom: 1px solid #C7C7C7;
    }
  }

  .header {
    display: flex;  
    padding: 0.6rem 0;
    height: 5.44rem;
    cursor: pointer;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }

  .notProcess{
    display: none;
  }

  .buttonOpen {
    position: absolute;
    top: 1.3rem;
    width: 1.62rem;
    height: 3rem;
    left: -1.62rem;
    background: #E0E0E0;
    border-radius: 0.5rem 0 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & path {
      fill: #fff;
    }

    &.process {
      background-color: #4FB2ED;

    }
    &.approved {
      background-color: #8FE532;
    }

    .count {
      font: normal normal 500 0.88rem var(--title-font);
      background-color: #fff;
      border-radius: 1rem;
      padding: 0 0.2rem;
      margin-bottom: 0.2rem;
    }
  }

  .rowItem {
    flex-basis: 13%;
    width: 13%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding: 0 0.6rem;

    &:not(:last-child) {
      border-right: 3px solid #CECECE;
    }

    &.checkBlock {
      display: flex;
      flex-basis: 20%;
      width: 20%;
      flex-grow: 0;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
    }

    .formBlock {
      display: flex;
    }

    &.doneRow {
      justify-content: space-between;
    }
  }

  .nameBlock {
    position: relative;
    width: 41%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 41%;
    flex-shrink: 0;
    flex-grow: 0;
    padding-left: 0;
    padding-right: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;

    .nameContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      max-width: 95%;
    }

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;  
    }

    & :global(.ant-tooltip-content) {
      word-wrap: break-word;
      white-space: normal;
    }
  }

  .content {
    padding: 0 1.3rem 1.3rem;
  }
}

.headerMaterial {
  display: flex;
  justify-content: space-between;
  font: normal normal 500 1rem var(--main-font);
  color: #000;
  padding: 0.5rem 0rem;
  
  .headerItem {
    flex: 1;
    padding:0 0.5rem;
    white-space: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 11%;
  }
  .name {
    flex-basis: 41%;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 0;
  }
  .name1{
    padding-left: 1.2rem;
  }
  .checkBlock {
    flex-basis: 15%;
    padding-right: 0;
  }
}

.contentJustification {
  padding: 1rem;
}

.blueLabel {
  background-color: #E6F4FC;
  cursor: pointer;

  &:hover {
    background-color: var(--color-background-primary);
    color: #fff;
  }
}

.blueLabelNonActive {
  background-color: #fff;
}

.hideBlockTitle {
  padding-left: 1.3rem;
  font-size: 1rem;
}

.hideMaterialBlock {
  display: flex;
  margin-top: 0.5rem;

  .materialItem {
    padding: 0.9rem 2.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .flex_1 {
    flex: 1;
    text-align: left;
  }

  .flex_4 {
    flex: 2;
    padding-left: 1.3rem;
  }

  .empty {
    flex: 1;
  }

  .title {
    border-radius: 8px;
    margin-right: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    .arrow {
      position: absolute;
      top: 0.2rem;
      right: 0.3rem;
    }
  }
}

.checkItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
}

.righted {
  text-align: right;
  justify-content: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkFile {
  position: relative;
  cursor: pointer;
}

.measure, .centeredContent {
  justify-content: center;
  text-align: center;
}