.switchBlock {
  display: flex;
  // align-items: center;
  justify-content: flex-start;
}

.text {
  cursor: pointer;
  margin-right: 1rem;
  font-family: var(--title-font);
}

.textRight {
  cursor: pointer;
  margin-left: 1rem;
}

.checkbox {

  // background-color: #EBEBEB;
  // &.ant-switch-checked {
  //   background-color: #4FB2ED;
  // }
  &::before {
    // border: none;
    // background-color: #C4C4C4;
    // width: 23px;
    // height: 23px;
  }

  & :global(.ant-switch) {
    &:after {
      background: #B2B2B2 !important;
    }
  }
}

// .checkbox.ant-switch-checked {
//   background-color: #4FB2ED;
// }


//

//.ant-switch-checked::after 