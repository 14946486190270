.notyWrapper {
  height: 3.38rem;
  width: 3.94rem;
  display: flex;
  align-items: center;
}

.notyCounterBlock {
  position: relative;
  left: 0;
  cursor: pointer;
  
  @media (max-width: 1440px) {
    top: 0.125rem;
  }

  .notyCounter {
    position: absolute;
    padding: 0.125rem 0.375rem;
    background-color: #ff6fa1;
    border-radius: 0.75rem;
    bottom: 63%;
    left: 45%;
    font-size: 0.688rem;
    font-weight: 500;
    color: #fff;
  }
}

.innerModal {
  min-height: 10rem;
  .notyQuantity {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }

  .notyList {
    padding: 0 5px 13px 5px;
    margin-top: 20px;
    border-bottom: 1px solid #bcbcbc;
  }
}

.marginNoty {
  margin: 31px 0 0 38px;

  .notyTextTitle {
    color: #000;
    font-size: 22px;
    font-weight: 400;
  }
}

.showAllElements {
  padding-top: 11px;
  margin-right: 18px;
  display: flex;
  justify-content: flex-end;
}

.innerLargeModal {
  height: 100%;
  overflow: auto;

  .notyList {
    padding: 0 5px 13px 5px;
    margin-top: 20px;
    border-bottom: 1px solid #bcbcbc;
  }
}

.emptyPlaceholder{
  height: auto !important;
  margin-top: 2.5rem;
}
