.title {
  color: #969696;
}

.numericValue {
  font-size: 1rem;
  line-height: 1.125rem;
}

.amount {
  font: normal normal 0.875rem/1.125rem var(--title-font);
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-align: right;
}

.bold, .bold .numericValue {
  font-weight: 500;
}