.container {
  margin-bottom: 1.8rem;
  padding: 0.5rem 0.6rem;
  text-align: center;
  position: relative;
  height: 3.38rem;
  width: 3.94rem;
  align-items: center;
  justify-content: center;
  border-left: solid 6px transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-height: 1020px) {
    margin-bottom: 1.4rem;
  }
  @media (max-height: 900px) {
    margin-bottom: 1rem;
  }
  @media (max-height: 820px) {
    margin-bottom: 0.5rem;
  }
  @media (max-height: 740px) {
    margin-bottom: 0.2rem;
  }

  &:hover {
    .titlePopup {
      display: block;
    }
  }
  &.active {
    background-color: #e8e8e8;
    border-radius: 4px;
    border-left: solid 6px #4FB2ED;
  }
  .icon {
    // width: 1.6rem;
  }
  .titlePopup {
    display: none;
    position: absolute;
    padding: 5.3px 2rem;
    //border: 1px solid #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #4FB2ED;
    top: 0.8rem;
    left: calc(100% + 1.4rem);
    color: #fff;
    z-index: 9999;
    white-space: nowrap;
    border-left: none;
    border-radius: 0 4px 4px 0;
    font: 0.9em var(--title-font);

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      // box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    }
    &:after {
      top: 0px;
      border-color: transparent #4FB2ED transparent transparent;
      border-width: 0.9rem 1.5rem;
    }
    &:before {
      top: -1px;
      border-color: transparent #fff transparent transparent;
      border-width: 0.93rem 1.63rem;
      right: 100%;
    }
  }
}
.active .container {
  background-color: #e8e8e8;
  border-radius: 4px;
  border-left: solid 6px #4FB2ED;
}
