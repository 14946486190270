.bubbles {
  display: flex;
  flex-wrap: wrap;
  gap: 0.53rem;
  margin-bottom: 0.35rem;
}

.bubble {
  border-radius: 0.8rem;
  color: white;
  background-color: var(--color-background-primary);
  padding: 0.5rem 0.66rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.4rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  max-height: 80%;
  overflow-y: auto;
}

.input {
  width: 10rem;
}