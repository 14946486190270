.container {
  background-color: #fff;
}

.errorMessage {
  text-align: center;
  font-size: 1.2em;
  padding: 2rem;
}

.head {
  position: sticky;
  z-index: 21;
  top: 0;
  background-color: #fff;

  &::before {
    content: "";
    background-color: #fff;
    width: 100%;
    height: 1.88rem;
    top: -1.88rem;
    position: absolute;
}
}

@media screen and (max-width: 1439px) {
  .outerTemplate {
    padding-left: 3.88rem;
    padding-right: 2rem;
  }
}