.bar {
    display: flex;
    width: 100%;
    align-items: center;
    padding-bottom: 0.3125rem;
    //justify-content: space-between;

    & > span {
        opacity: 0.7;
        margin-left: 1rem;
    }

    & > div {
        margin-left: auto;
        display: flex;
        align-items: center;
    }
}

.activeBar {
    & > span {
        opacity: 1;
    }
}

.trigger {
    margin-left: 0.5rem;
}

.disabledSelect {
    opacity: 0.7;
}

.optionBlock {
    color: #000 !important;
}

.modal {
    padding: 2rem 1.5rem;
    & h2 {
        font-size: 1.375rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
}

.tabs {
    width: fit-content;
}

.btns {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1rem 0;
    display: flex;
    justify-content: space-evenly;
    border-top: 1px solid #EBEBEB;
}

.input {
    margin-bottom: 0.5rem;
}

.choose {
    font-size: 1.125rem;
    font-weight: 700;
    margin: 1rem 0;
}

.subtitle {
    opacity: 0.7;
    margin-bottom: 1rem;
}

.select {
    margin-left: 0.5rem;
    background-color: #4FB2ED;
    width: 15rem;
    border: none !important;

    & > span {
        color: #fff;
    }
}

.selectedCount {
    font-weight: 500;
}