@import "src/scss/dimensions";

.label {
  font: normal normal 400 1rem var(--main-font);
  color: #666666;
  margin-bottom: 0.35rem;
}

.selectContainer {
  position: relative;
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5625rem;
  border: 1px solid var(--color-border-field18);
  background-color: var(--color-background-main);
  border-radius: 0.75rem;
  padding: 0.6875rem 1.125rem;
  font: 1rem AvenirNextCyr;
  cursor: pointer;

  &.isOpen {
    border-color: #4FB2ED;
    border-radius: 0.5rem 0.5rem 0 0;
    z-index: 10000;
    position: relative;
    border-bottom: none;
  }

  &.disabled {
    color: #A0A0A0;
    cursor: default;
  }

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .arrow {
    transition: all 0.2s ease;
    width: 1rem;
    margin-left: 0.25rem;
  }

  .arrowReverse {
    transform: rotate(-180deg);
  }

  .isOpen {
    display: block;
  }
}

.optionsBlock {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  top: 2.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
  position: absolute;
  background: #fff;
  z-index: 9999;
  max-height: 11.8rem;
  overflow-y: auto;
  border: 1px solid var(--color-border-primary);
  padding: .25rem 0;
}

.errorMessage {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #FF0558;
}

.option {
  border-radius: 0.5rem;
  padding: 0.6rem 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;

  &:hover, &.selected {
    background: #4FB2ED;
    color: #fff;
  }
}

.withDescription {
  width: 14rem;

  .option {
    display: flex;
    align-items: flex-start;
    white-space: normal;
    text-overflow: inherit;
  }

  .name {
    width: 2rem;
    margin-right: 0.25rem;
    flex-shrink: 0;
  }
}

.blue {
  border-color: var(--color-border-primary);
}

.baseColor {
  color: var(--color-font2) !important;
}

.small {
  padding: 0.5rem 1rem 0.5rem 0.5rem;

  @include breakpoint-to($laptop) {
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
}
}

.moreButton {
  position: sticky;
  z-index: 22222222;
  bottom: 0;
  margin: 0.25rem auto 0;
}

.hiddenOptions {
  height: 14rem;
  max-height: 14rem;
}

.spinner {
  display: flex;
  height: 5rem;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1600px) {
  .hiddenOptions {
    height: 11.8rem;
  }
}