.header {
    height: 2.5rem;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    /* position: sticky;
    z-index: 3;
    background-color: #fff;
    top: 4rem; */

    & h1 {
        margin-left: 2rem;
        font-weight: 500;
        font-size: 1.125rem;
        margin-bottom: 0;
        margin-right: auto;
    }
}

.btns {
    display: flex;
    align-items: center;

    & button {
        margin-left: 1rem;
    }
}

.projectName {
    font-size: 1.125rem;

}

.sum {
    font-size: 1.125rem;
    font-weight: 500;
}

.divider {
    margin: 0 1rem;
    height: 2.5rem;
    width: 2px;
    background-color: #C7C7C7;
    border-radius: 2px;
}