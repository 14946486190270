.addWorkerModal {
  font: normal normal 1rem/1.25rem var(--title-font);
  height: 100%;
}

.textSmall {
  font-size: 0.875rem;
  line-height: 1.063rem;
}

.medium {
  font-weight: 500;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.header {
  padding: 1.625rem 2.438rem 1.25rem;
  display: flex;
  border-bottom: 1px solid var(--color-border-field5);

  @media screen and (max-width: 1480px) {
    padding: 1.625rem 1.438rem 1.25rem;
  }
}

.avatarContainer {
  position: relative;
}

.avatar {
  width: 7rem;
  height: 7rem;
  border-radius: 0.5rem;
  object-fit: cover;
}

.avatarNone {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.188rem;
  border-radius: 0.5rem;
  background-color: var(--color-border-field3);

  & img {
    width: 2.625rem;
    height: 2.625rem;
  }
}

.addAvatarIcon {
  position: absolute;
  top: -10%;
  right: -10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0.313rem;
  background-color: var(--color-background-field12);
  cursor: pointer;

  @media screen and (max-width: 1441px) {
    top: -8%;
    right: -8%;
  }

  & img {
    width: 1.125rem;
    height: 1.125rem;
  }
}

.addAvatarInput {
  position: absolute;
  top: 0;
  top: -15%;
  right: -15%;
  width: 45px;
  height: 45px;
  opacity: 0;
  cursor: pointer;
  
  &::-webkit-file-upload-button {
    cursor: pointer;
  }
}

.headerInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 1rem;
}

.title {
  font-family: var(--title-font);
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 0;
}

.dotsIfLong {
  width: 20vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}

.headerInfoItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > span, & .switcher {
    width: 45%;
  }

  @media screen and (max-width: 1441px) {
    & > span {
      width: 50%;
      margin-right: 0.813rem;
    }
  }
}

.subtitle {
  font-size: 1rem;
  margin-bottom: 0.875rem;
}

.selectContainer {
  width: 100%;
  margin: 0.563rem 0;
}

.partContainer {
  padding: 1.125rem 2.438rem 0;

  @media screen and (max-width: 1480px) {
    padding: 1.125rem 1.438rem 0;
  }
}

.formContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 7rem;
  padding-top: 0;
}

.fieldContainer {
  width: 45%;
  margin-bottom: 1.5rem;

  & label {
    font-family: var(--title-font) !important;
    font-weight: 500 !important;
    color: #000;
  }

  & input {
    color: #000;
    height: 2.64rem;

    &::placeholder {
      color: #000;
    }
  }
}

.fieldSelect {
  margin-bottom: 0;
}

.calendarSelect {
  height: 2.64rem;
}

.calendarOptions {
  left: 0;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 2.64rem;
}

.files {
  width: 100%;
  margin-top: 1rem;
}

.deleteButton {
  margin-top: 1.188rem;
}

.footer {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  width: 100%;
  margin-top: 1rem;
  border-top: 1px solid #BCBCBC;

  & button:first-child {
    margin-right: 1.125rem;
  }
}