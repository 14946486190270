.button {
  border-radius: 0.63rem;
  background-color: var(--color-background-primary);
  padding: 0.5rem 1.2rem 0.5rem 0.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: var(--main-font);
  color: #fff;
  width: fit-content;

  .arrowLeft {
    height: 0.8rem;
    margin-right: 1rem;
  }
}
