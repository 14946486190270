.footer {
  padding: 0 2.5rem;

  .commentTextarea {
    margin-top: 1rem;
    text-align: left;
    overflow: auto;

    &::placeholder {
      font-size: 1.125rem;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
}