.sliderTitle{
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 400;
}
.logList {
  padding-left: 0.5rem;
  overflow: auto;

  td, th {
    font-weight: 400;
    padding: 0.92857rem 0.71429rem;
    font-size: 12px;
    text-align: left;
  }

  td {
    font-weight: 300;
    border-top: 1px solid #E1E8EE;
  }
}
