.container {
  margin-left: -1.875rem;
  margin-right: -1rem;
  border-bottom: 2px solid #A8D0E8;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.thead, .row {
  display: flex;
  align-items: center;
  padding: 0.875rem 1.875rem;
  border-bottom: 1px solid #C7C7C7;
}

.thead {
  font-weight: 500;
}

.row {
  width: 100%;
  height: 3rem;
  border-bottom: 1px solid #C7C7C7;
}

.numberCol {
  width: 5%;
}

.nameCol {
  width: 43%;
}

.countCol, .measureCol {
  width: 8%;
}

.priceCol, .amountCol, .retailPriceCol {
  width: 12%;
}

.priceCol, .amountCol, .retailPriceCol {
  text-align: right;
}

.numberCol, .nameCol, .countCol, .measureCol, .priceCol, .retailPriceCol {
  padding-right: 1rem;
}

.countCol, .measureCol {
  text-align: center;
}

.bottomFinancial {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #4FB2ED;
  font-weight: 500;
  padding-right: 0.75rem;

  .bottomTitle {
    display: flex;
    flex-direction: column;

    .name {
      color: #000;
      text-align: right;
      margin-bottom: 0.5rem;
    }
  }

  .bottomFinancialNumbers {
    display: flex;
    flex-direction: column;

    .val {
      min-width: 6.5rem;
      margin-left: 1rem;
      text-align: right;
      margin-bottom: 0.5rem;
    }
  }

  .measureIcon {
    display: flex;
    flex-direction: column;

    .measure {
      margin-left: 0.75rem;
      margin-bottom: 0.5rem;
    }
  }
}

.amountsContainer {
  margin-top: -0.75rem;
  margin-left: auto;
}

.amountsContainer, .comment {
  padding: 0 1rem;
}

.comment {
  display: flex;
  align-items: baseline;
  font: normal normal 300 1.125rem/1.25rem var(--title-font);
}

.commentTitle {
  width: 12rem;
  margin-right: 2.375rem;
  font: normal normal 400 1.125rem/1.25rem var(--title-font);
  flex-shrink: 0;
}

.footer {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}