.radioSquares {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

.label {
  font-size: 1rem;
  font-weight: 500;
}

.container {
  display: grid;
  gap: 0.75rem;
}

.element {
  border-radius: 0.5rem;
  outline: 1px solid var(--color-background-primary);
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem 0.5rem;
  min-width: 100%;
  background-color: white;
  cursor: pointer;
  font-size: 0.875rem;
}

$circleSize: 0.875rem;

.circle {
  width: $circleSize;
  height: $circleSize;
  min-height: $circleSize;
  max-height: $circleSize;
  min-width: $circleSize;
  max-width: $circleSize;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: #707070;
  position: relative;
}

.activeElement {
  background-color: #ededed;
  outline: 1px solid #ededed;
  .circle {
    background-image: radial-gradient(#313131 45%, transparent 46%, transparent 100%);
  }
}
