.container {
  font-size: 1rem;
  font-weight: 400;

  & thead {
    position: sticky;
    top: 3.3rem;
    background-color: #fff;
    z-index: 2;

    & th {  
      border-bottom: 1px solid var(--color-background-primary);
    }
  }

  .actionBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0;

    .addButtonClass {
      display: flex;
      align-items: center;
    }

    .plusIcon {
      display: flex;
      align-items: center;
      margin-left: 1rem;
    }

    .dateBlock {
      display: flex;
      background-color: #f4f4f4;
      padding: 0 0.4rem;
      border-radius: 10px;
    }

    .parametr > span {
      font-size: 1.125rem;
    }
  }

  .devider {
    height: 2rem;
    width: 0.19rem;
    background: #cecece;
    position: absolute;
    right: 0rem;
    top: 0.9rem;
  }

  .titleBlock {
    display: flex;
    align-items: center;
    justify-content: start;
    white-space: nowrap;
  }

  .cellBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    text-overflow: ellipsis;

    & > div {
      width: 100%;
      text-overflow: ellipsis;
    }

    &:after {
      width: 2px;
      background: #000;
      height: 90%;
    }

    .arrow {
      display: block;
      opacity: 0;

      &.active {
        cursor: pointer;
        opacity: 1;
      }
    }
  }

  .cellDate {
    text-align: center;
  }

  .tableClass {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0.3rem;
    position: relative;
  }

  .headerClass {
    color: #000;
    font-size: 1rem;
    font-weight: 500;
    padding: 0 1rem;
    margin-left: 1rem;
    text-align: left;
  }

  .rowClass {
    height: 3.75rem;
    font-size: 1em;
    font-family: var(--title-font);
    position: relative;

    td {
      background-color: #f4f4f4;

      &:first-child {
        border-radius: 10px 0 0 10px;
      }

      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
  }

  .cellClass {
    padding: 0 1.31rem;
    box-sizing: border-box;
    position: relative;
  }

  .titleClass {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    padding-right: 1rem;
  }

  .inputCount {
    width: 63%;
    margin-right: 1rem;
    background-color: #fff;
    border: none;

    &.inputActive {
      background-color: #ccecff;
    }
  }

  .textCenter {
    text-align: center;
  }

  .roundLabel {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50px;

    &.redLabel {
      background-color: #db136f;
    }
  }
}

.matching {
  width: 2rem !important;
}
