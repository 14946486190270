.container {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    background-color: #fff;
    z-index: 9;
    position: sticky;
    top: 0;

    &::before {
        content: "";
        position: absolute;
        background-color: #fff;
        width: 100%;
        height: 1.88rem;
        top: -1.88rem;
    }

    &::after {
        content: "";
        position: absolute;
        background-color: #fff;
        width: 100%;
        height: 1rem;
        bottom: -1rem;
    }

    & :global(.monthSlider) {
        height: 2.6rem;
    }
}

.months {
    margin-right: auto;
}

.add {
    margin-left: 2rem;
}