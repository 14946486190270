.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  min-height: calc(100vh - 4.19rem);
  margin-left: 35%;
  position: absolute;
  top: 0;
  margin-top: 2.81rem;
}

.tree {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  padding-bottom: 3rem;
  position: relative;
  min-height: inherit;
}

.branch {
  display: flex;
  min-height: 3rem;
  position: absolute;
}

.activeBranch {
  background-color: rgba(216, 241, 255, 0.5);
  z-index: 97;
}

.diagramIntervalWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 0.5rem;
  height: 2rem;
  box-sizing: border-box;
  cursor: default;
  z-index: 0;

  & > svg {
    z-index: 0;
    display: block;
    position: relative;
    height: 2rem;
    z-index: -1;
  }
}

.breakBubble {
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #707070;
  padding: 0 0.625rem;
  border-radius: 0.625rem;
  transform: translate(-0.45rem, -0.45rem);
  cursor: default;
}

.breakBubbleHighlighted {
  border-color: #7061ea;
}

.work,
.planFull,
.material {
  flex: 1;
  max-width: calc(100% - 0.88rem);
  overflow: hidden;
  height: 2rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;

  & > span {
    display: block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

$braceColor: #4fb2ed;

.planBrace {
  position: absolute;
  height: 0.25rem;
  width: calc(100% - 1rem);
  background-color: $braceColor;
  top: 0;
  cursor: pointer;
}

.planBrace:after,
.planBrace:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
}

.planBrace:after {
  right: -0.25rem;
  border-style: solid;
  border-width: 0.25rem 0 0 0.25rem;
  border-color: transparent transparent transparent $braceColor;
}

.planBrace:before {
  left: -0.25rem;
  border-style: solid;
  border-width: 0 0 0.25rem 0.25rem;
  border-color: transparent transparent $braceColor transparent;
}

.planBrace:hover {
  z-index: 100;
}

.tooltip {
  position: absolute;
  bottom: calc(100% + 0.8125rem);
  background: #c1e7fd;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  width: max-content;
  text-align: center;
  display: block;
  padding: 0.5rem 0.625rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  transform: translateX(-50%);
  border: 1px solid #4fb2ed;
  z-index: 2;
}

.bottomTooltip {
  z-index: 4;
  height: 2rem;
  top: 0;
  transform: translateX(-50%) translateY(60%);

  &:before {
    transform: scaleY(-1);
    top: calc(-95% + 1px) !important;
  }

  &:after {
    transform: scaleY(-1);
    top: calc(-95%) !important;
  }
}

.tooltip:before {
  content: "";
  display: block;
  border: 0.8125rem solid transparent;
  border-top: 0.8125rem solid #c1e7fd;
  position: absolute;
  top: calc(100% - 1px);
  z-index: 1;
  right: calc(50% - 0.44rem);
}

.tooltip:after {
  content: "";
  display: block;
  border: 0.82rem solid transparent;
  border-top: 0.82rem solid #4fb2ed;
  position: absolute;
  top: 100%;
  z-index: 0;
  right: calc(50% - 0.44rem);
}

.planBg,
.doneBg,
.receivedBg,
.paymentBg,
.paidBg,
.topayBg,
.confirmedBg {
  position: absolute;
  height: 100%;
  left: 0;
  z-index: 0;
}

.planBg {
  background-image: url("../../../../../images/ticket-background.svg");
}

.doneBg {
  background-image: repeating-linear-gradient(
    135deg,
    #d5c9df,
    #d5c9df 1.5px,
    transparent 1.5px,
    transparent 2.8px
  );
}

.receivedBg {
  background-color: #d5c9df;
}

.confirmedBg {
  background-image: repeating-linear-gradient(
    135deg,
    #4fb1eb,
    #4fb1eb 1.2px,
    transparent 1.2px,
    transparent 4.46px
  );
}

.topayBg {
  background-color: #4fb1eb;
}

.paidBg {
  background-color: #6fc79b;
}

.workName {
  z-index: 2;
}

.removeArrowIcon {
  width: 0.625rem;
  height: 0.625rem;
  cursor: pointer;
}

.arrowAcceptElement {
  height: inherit;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
}

.popupAnchor {
  position: absolute;
  bottom: 0;
  top: 100%;
  left: 50%;
  z-index: 999;
}
