
.branch {
  position: absolute;
  width: 100%;
  background-color: #fff;
  border-radius: .625rem;
  height: 3rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  box-sizing: border-box;
  padding: 0 .625rem 0 1rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  &:hover{
    z-index: 1;
    align-items: flex-start;
    height: auto;
    min-height: 3rem;
    padding-top: .765rem;
    padding-bottom: .765rem;
    .branchName{
      white-space: normal;
    }
  }
}

.branchChild {
  padding-left: 2rem;
}

.branchExpenditure {
  padding-left: 4rem;
}
.branchExpenditureChild {
  padding-left: 6rem;
}

.branchNameWrapper {
  width: calc(100% - 4.5rem);
  display: flex;
  align-items: center;
  cursor: default;
}

.branchName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.branch:nth-child(odd) {
  background-color: #eff7fc;
}

.activeBranch, .branch:hover {
  background-color: #4FB2ED !important;
  color: #fff !important;
  border-radius: .625rem 0 0 .625rem;

  .count {
    background-color: #fff;
    color: #4FB2ED;
  }

  .branchCheckbox{
    border-color: white;
  }
  .branchCheckbox.branchCheckboxFalse:before, .branchCheckbox.branchCheckboxFalse:after{
    background-color: white;
  }
  .bubbleRuble, .bubbleInfo {
    display: block;
  }
}

.branchCheckbox {
  min-width: .875rem;
  width: .875rem;
  height: .875rem;
  border: 1px solid #d1d1d1;
  border-radius: .25rem;
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
}

.branchCheckbox.branchCheckboxTrue::after {
  content: '';
  position: absolute;
  width: 0.0875rem;
  height: .5rem;
  background-color: #7c7c7c;
  top: .125rem;
  left: calc(50% - 0.04375rem);
}

.branchCheckbox.branchCheckboxFalse:before {
  content: '';
  position: absolute;
  width: .5rem;
  height: 0.0875rem;
  background-color: #7c7c7c;
  top: calc(50% - 0.04375rem);
  left: .125rem;
}

.branchChildActive {
  color: #4FB2ED;
}

.count {
  background: #4FB2ED;
  min-width: 2.25rem;
  padding: 0 .375rem;
  height: 1.375rem;
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  border-radius: .69rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubbleRuble, .bubbleInfo {
  display: none;
}

.branchBubbles{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > div:not(:last-child){
    margin-right: .75rem;
  }
}