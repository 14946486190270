@import "src/scss/dimensions";

.top {
  font-weight: 500;
  justify-content: space-between;
  display: flex;
  padding: 0.8rem 0;
  width: 66%;
  margin-left: auto;

  .firstTitle, .secondTitle {
    text-align: center;
  }

  .firstTitle {
    width: 72%;
    color: var(--color-font-primary);
  }

  .secondTitle {
    width: 28%;
  }
}

.tableContainer {
  overflow-y: auto;
  margin-bottom: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border: 1px solid #E8E8E8;
  max-height: 42rem;

  &::-webkit-scrollbar {
    display: none;
  }
}

.table {
  font: normal normal 1rem/1.25rem var(--title-font);

  .blueTitle {
    color: var(--color-font-primary);
  }

  .dayItem {
    font-weight: 500;
  }

  .dayItemHeader,
  .dayItem {
    width: 100%;
    height: 3rem;

    &:not(:nth-child(16)):not(:nth-child(32)) {
      border-right: 1px solid #E8E8E8;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    .info {
      width: 100%;
      height: 100%;

      .status, .value {
        font-family: var(--main-font);
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .status {
        border-bottom: 1px solid #E8E8E8;
      }
    }
  }
}

.boxButtonPaginator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 0;
  background-color: var(--color-background-main);
  padding-top: 0.25rem;
  border-top: 1px solid #E8E8E8;
}

.borderNone {
  border: none !important;
}

.headerContent {
  display: flex;
  padding-left: 1rem;
}

.row {
  padding-left: 1rem;
}

.durations {
  display: flex;
  flex-direction: column;

  .columns, .durationRows, .durationRow {
    height: 50%;
  }

  .columns, .durationRow:not(:last-child) {
    border-bottom: 1px solid #E8E8E8;
  }

  .durationRows {
    display: flex;
    flex-direction: column;
  }

  .columns {
    display: flex;
    align-items: center;
  }

  .column {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .column, .durationRow {
    text-align: center;
  }

  .column:not(:last-child) {
    border-right: 1px solid #E8E8E8;
  }
}

.row {
  display: flex;
  border-bottom: 1px solid var(--color-border-primary);
}

.numberColumn, .nameColumn, .tableNumColumn {
  padding-right: 1rem;
}

.numberColumn {
  width: 8%;
}

.nameColumn {
  width: 62%;
  display: flex;
  flex-direction: column;
}

.postInfo {
  opacity: 0.5;
}

.tableNumColumn {
  width: 31%;
}

.tableNumItem {
  text-align: center;
  padding: 0;
}

.columnsGroup {
  width: 33%;
  display: flex;
  padding-top: 1rem;
}

.tableHeader {
  position: sticky;
  top: 0;
  background-color: var(--color-background-main);
  z-index: 1;

  .monthBlocks {
    min-width: 19%;
  }

  .columnsGroup, .monthBlocks {
    font-weight: 500;
  }

  .columnsGroup {
    padding: 0 0 1rem;
    align-items: flex-end;
  }
}

.dayTableCell {
  width: 48%;
  border-left: 1px solid #707070;
  border-right: 1px solid #707070;
}

.dayBlock {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dayRow {
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid #E8E8E8;
  }
}

.dayItemHeader {
  font-family: var(--main-font);
  opacity: 0.5;
}

.greyRow {
  width: 100%;
  height: 1.625rem;
  background: #F4F4F4;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-left: 1rem;

  & > * {
    flex-shrink: 0;
  }

  .one {
    width: 12%;
  }

  .two {
    width: 16%;
  }

  .three {
    width: 27.2%;
  }

  .four {
    width: 30.1%;
  }

  .five {
    width: 9.6%;
  }

  .six {
    width: 5%;
  }
}

@include breakpoint-to($laptop) {
  .top {
    width: 57%;

    .firstTitle {
      width: 67%;
    }

    .secondTitle {
      width: 33%;
    }
  }

  .columnsGroup {
    width: 42%;
  }

  .dayTableCell {
    width: 39%;
  }

  .greyRow {
    .two {
      width: 23%;
    }

    .three {
      width: 25%;
    }

    .four {
      width: 25.3%;
    }
  }
}

.emptyPlaceholder{
  margin-top: 2rem;
}