.pair {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.25rem;

  &.centered {
    align-items: center;
  }
}

.calendarPopup {
  transform: translateX(33%) !important;
}
