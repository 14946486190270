.head {
    margin-bottom: 0.75rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.title {
    font-weight: 500;
}

.svg {
    transform: rotate(0deg);
    margin-left: -0.5rem;
    margin-right: 0.5rem;
}

.activeSvg {
    transform: rotate(180deg);
}