.container {
  width: 69.5vw;
  height: auto;

  .headerContent {
    padding: 0 2.56rem;
  }

  .topSide {
    border-bottom: 1px solid #D3D3D3;
    position: relative;
  }
}

.field1 {
  width: 65%;
}

.field2 {
  width: 16%;
}

.field3 {
  width: 16%;
}

.field4 {
  width: 20%;
  margin: 0 0.3rem;
}

.field5 {
  margin-bottom: 10px;
}

.field6 {
  margin-top: 18px;
}

.field1 {
  width: 65%;
}

.field2 {
  width: 16%;
}

.field3 {
  width: 16%;
}

.field4 {
  width: 20%;
  margin: 0 0.3rem;
}

.field5 {
  margin-bottom: 10px;
}

.field6 {
  margin-top: 18px;
}

.field1 {
  width: 65%;
}

.field2 {
  width: 16%;
}

.field3 {
  width: 16%;
}

.field4 {
  width: 20%;
  margin: 0 0.3rem;
}

.field5 {
  margin-bottom: 10px;
}

.field6 {
  margin-top: 18px;
}

.actionBlock {
  padding: 0.875rem 2.5rem 0.875rem 2rem;
  justify-content: flex-end;
  display: flex;
  opacity: 1;

  button:first-child {
    margin-right: 0.5rem;
  }

  &.action {
    opacity: 0;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  .fieldRow1 {
    width: 30%;
  }

  .fieldRow2,
  .fieldRow3 {
    width: 8%;
  }

  .fieldRow4 {
    margin-top: 1.75rem;
  }

  .fieldRow5 {
    width: 20%;
  }

  .fieldRow6 {
    width: 16%;
    text-align: center;
    margin-top: 1.75rem;
  }

  &.row2 {
    margin-bottom: 1.5rem;
  }
}

.rowFirst {
  display: flex;
  justify-content: space-between;
  min-height: 5.5rem;
}


.secondaryField {
  width: 35%;
}


.primaryField {
  min-width: 5%;
  display: flex;
  align-items: center;
  margin-top: 2rem;
  justify-content: center;
}

.checkbox {
  margin-bottom: 1rem;

  span {
    border-color: #A0A0A0;
  }
}

.estimateField {
  width: 12%;
}