.container{
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  &:not(:last-child){
    margin-bottom: 1rem;
  }
}

.sender{
  margin-bottom: 0.5rem;
  font-weight: bold;
  .senderRole{
    font-weight: normal;
  }
}

.message{
  display: flex;
  align-items: flex-end;
  justify-content: revert;
  max-width: 80%;
  &:not(:last-child){
    margin-bottom: 0.5rem;
  }
  .text{
    background-color: #dbe8eb;
    padding: 0.5rem;
    word-break: break-word;
    border-radius: 5px;
  }
  .time{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    color: #5f5f5f;
  }
}

.systemMessage{
  width: 100%;
  text-align: center;
  color: #2f2f2f;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 2rem;
}