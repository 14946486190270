.comment {
  margin-bottom: 1.4rem;
  font-weight: 400;
}

.title {
  font-weight: 500;
}

.head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
}

.content {
  color: #707070;
  background: #E8F6FF;
  padding: 0.5rem;
  border-radius: 0.7rem;
  word-break: break-all;
}

.comments {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.emptyPlaceholder {
  height: auto !important;
  min-height: auto !important;
}

.addButton {
  height: auto;
}

.list {
  overflow-y: auto;
  padding-top: 0.5rem;
}