.tabs {
  margin-bottom: 2.4rem;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .boxEstimate {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .switchBox {
      margin: 0.5rem 0.5rem 0 0;
    }

    .buttonBox {
      margin-top: -3px;
    }
  }
}

.actionBlock {
  display: flex;

  .marginRight {
    margin-right: 1rem;

  }

  .plusIcon {
    margin-left: 1rem;
  }
}

.logoSvg {
  fill: #000 !important;
}

.tabIcons {
  display: flex;
  align-items: center;
  width: 100%;

  .imgIcon {

    .logoSvg {
      height: 90%;
      width: 90%;
    }
  }

  .switcherTitle {
    font-size: 1rem;
    font-weight: normal;
    margin-left: auto;
    margin-right: auto;

    span {
      font-weight: bold;
    }
  }
}

.headerContainer {
  padding: 1.4375rem 1.9375rem 0 1.9375rem;
}

.block1Title {
  display: flex;
  justify-content: space-between;
}

.closeIcon {
  cursor: pointer;
}

.title {
  font: normal normal 500 1.5rem var(--title-font);
  color: #000;
  margin-bottom: 0.625rem;
}
