.modal {
  width: 710px;
}

.selects {
  gap: 1.5rem;
  width: 100%;
  padding: 38px 1.8rem;
  border-bottom: 1px solid #bcbcbc;
  display: grid;
  grid-template-columns: 32% 43% 18%;
}

.label {
  font: normal normal 400 1rem var(--title-font);
  color: #666666;
  margin-bottom: 0.35rem;
}

.btns {
  padding: 10px 41px 12px;
  display: flex;
  width: 100%;
  justify-content: flex-end;

  & button {
    width: 180px;
    flex-basis: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.btn {
  margin-left: 2rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  background: #EFEFEF;
  color: var(--color-font-main);
  height: 2.625rem;
  font-size: 1rem;
  padding: 3px;
  width: 12.5rem;

  & * {
    flex-shrink: 0;
  }

  & path {
    fill: var(--color-font-main);
  }

  & > svg {
    margin: 0 3px;
    height: 1.5625rem;

    & path {
      fill: var(--color-font-main);
      stroke: var(--color-font-main);
    }
  }

  & button {
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      flex-grow: 1;
    }
  }

  &.activeBtn {
    & button {
      &:first-child {
        background-color: #4FB2ED;
        color: #fff;
        border-radius: 0.5rem;

        & path {
          fill: #fff;
        }

        &:hover {
          background-color: #4FB2ED;
        }
      }
    }
  }

  button {
    &:hover {
      background-color: #ddd;
    }
  }
}

.reestr {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0.8rem;

  & > svg {
    margin-left: 1rem;
  }
}

.upload {
  padding: 0 0.8rem;
}