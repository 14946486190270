.ordersComparison {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
}

.header {
  width: 100%;
  background-color: #f5f5f5;
  z-index: 10;
  position: sticky;
  top: 0;
  & > div {
    position: sticky;
    top: 0;
    background-color: #f5f5f5;
    border-bottom: 1px solid var(--color-border-primary);
  }
}

.expenditure,
.header {
  display: grid;
  grid-auto-columns: 20%;
  grid-template-rows: 1fr;
  min-height: 25%;
}

.order,
.request {
  border-right: 1px solid #d5d5d5;
  grid-row: 1;
}

.expenditureName,
.switchBlock {
  position: sticky;
  left: 0;
  display: flex;
  align-items: flex-start;
  font-size: 0.875rem;
  border-right: 1px solid var(--color-border-primary);
  padding: 0.938rem 1.312rem;
}

.expenditureName {
  background-color: white;
}

.blueBg {
  background-color: #eff9ff;
}

.switchBlock {
  z-index: 2;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}
