$gray-300: #5D6164;
$background-page-color: #f7f9fa;
$main-color: #00B4F2;
$gray-200: #B1B6BB;
$order-gray: #9b9b9b;
$main-color: #00B4F2;
$global-border: 1px solid $main-color;

@function rem($value) {
  @return calc($value / 16);
}

.documents-list {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
}

.documents-list__item {
  width: 8.75rem;
  padding: {
    right: 1.25rem;
    left: 1.25rem;
    bottom: 1.375rem;
  }
  border-right: 1px solid $gray-200;
  @media (max-width: 375px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &:not(.documents-list__item_add-file) {
      border: {
        right: none;
        bottom: 1px solid $gray-200;
      }
    }
  }
}

.documents-list__item-title {
  font-size: 0.875rem;
  margin-bottom: 0.125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  display: inline-block;
}

.documents-list__date {
  font-size: 0.625rem;
  color: $order-gray;
}

.documents-list__remove {
  font-size: 0.75rem;
  color: $order-gray;
  cursor: pointer;
}

.documents-list__info {
  border-bottom: 1px solid $gray-200;
  padding-bottom: 0.625rem;
  margin-bottom: 0.625rem;
}

.documents-list__icon-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background: #fff;
  box-shadow: 2px 4px 4px 0 rgba(249, 249, 249, 0.5);
  margin-bottom: 0.625rem;
  &_add {
    background: none;
    box-shadow: none;
    color: $main-color;
    .documents-list__icon {
      font-size: 1.5rem;
    }
  }
}

.document-list__icon-wrapper {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  //background: url("../images/add-new-doc.svg") no-repeat center center;
  background-size: 14px 20px;
}

.files-list__icon-wrapper {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background: url("../images/clip.svg") no-repeat center center;
  background-size: 1.25rem;
}
.files-list__icon-wrapper-filled {
  width: 2rem;
  height: 2rem;
  background: url("../images/clip-filled.svg") no-repeat center center;
  //background-size: 1.25rem;
}

.document-list__icon-wrapper-filled {
  width: 2rem;
  height: 2rem;
  //max-height: 100%;
  background: url("../images/add-new-doc-filled.svg") no-repeat center center;
  background-size: contain;
}

.document-list__icon-wrapper-filled-green {
  width: 2rem;
  height: 2rem;
  background: url("../images/add-new-doc-filled-green.svg") no-repeat center center;
  max-height: 100%;
  background-size: contain;
  flex-shrink: 0;
}

.document-list__icon-wrapper--black {
  width: 2rem;
  height: 2rem;
  background: url("../images/add-new-doc.svg") no-repeat center center;
  max-height: 100%;
  background-size: contain;
}

.documents-list__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.125rem;
  height: 3.125rem;
  font-size: 1.3rem;
  border: 2px solid $main-color;
}
