.container {

  .dayNameBlock {
    display: flex;
    margin: 0 auto;
    max-width: 19.03125rem;
    flex-wrap: wrap;
  }

  .dayName {
    font-weight: 400;
    color: #000;
    text-align: center;
    font-size: 1rem;
    height: 1.75rem;
    width: 2.71875rem;
    margin-bottom: 0.1875rem;
  }

  .dayNumber {
    font-weight: 400;
    color: #707070;
    text-align: center;
    font-size: 1rem;
    height: 1.75rem;
    width: 2.71875rem;

    margin-top: 0.1875rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dayNumber:first-child {
    background-color: #000;
  }

  .holidays {
    color: #4FB2ED;
  }

  .activeDate {
    color: #fff;
    background-color: #4FB2ED;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: 1.75rem;
    width: 2.71875rem;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .activeDateEnd {
    color: #fff;
    background-color: #4FB2ED;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 1.75rem;
    width: 2.71875rem;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}