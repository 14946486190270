.paymentTerm {
  width: 100%;
  margin-top: 0.5rem;
  justify-content: space-between;

  &, .fields {
    display: flex;
    align-items: center;
  }

  .input, .select {
    height: 100%;
  }

  .select {
    width: 13rem;
  }

  .input {
    margin-left: 0.5rem;
    width: 5rem;
  }

  .deleteButton {
    margin-left: 0.5rem;
  }
}
