.connectPoint,
.acceptPoint {
  position: absolute;
  z-index: 89;
  top: 50%;
  width: 0.75rem;
  height: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connectPoint {
  transform: translate(30%, -50%);
  right: 0;
  cursor: pointer;
  &:active {
    cursor: grabbing;
  }
}

.acceptPoint {
  left: 0;
  transform: translate(0, -50%);
  border-radius: 50%;
  background: var(--color-background-primary);
}
