.container {
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 0.75rem;
    background: #f4f4f4;
    position: relative;
    cursor: pointer;

    & b {
        font-size: 0.875rem;
        margin-right: 0 !important;
    }
}

.arrow {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
}

.name {
    margin-bottom: 1.25rem;
    font-weight: 400;
}

.grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 0.5rem;
    grid-template-rows: auto auto;
    grid-template-areas: "date empty" "name count";
}

.pair {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;

    & * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & p {
        width: 50%;
        flex-basis: 50%;
        margin-bottom: 0;
    }

    & b {
        width: 50%;
        flex-basis: 50%;
        display: flex;
        align-items: center;
    }

    & .label {
        width: auto;
        flex-basis: auto;
        margin-right: 0.75rem;
    }

    & .person {
        flex-grow: 1;
        max-width: 100%;
        font-weight: 400;
    }

    & svg {
        margin: 0 0.9rem;
        width: 1rem;
        flex-shrink: 0;
    }
}

.hr {
    width: 100%;
    height: 1px;
    background-color: #dbdbdb;
    margin: 0.5rem 0;
}

.date {
    font-weight: 500;
    overflow: visible;
}

.time {
    flex-basis: 50%;
    width: 50%;
    font-weight: 400;
    overflow: visible;
}

.jcsb {
    justify-content: space-between;
}

.countPair {
    color: #757575;
    font-weight: 400;
    display: flex;
    align-items: center;
    flex-basis: 50%;
    overflow: visible;
}

.count {
    display: block;
    margin-left: 1rem;
    font-weight: 500;
    color: #000;
    overflow: visible;
}

.label {
    color: #757575;
    font-weight: 400;
    flex-shrink: 0;
}

.measure {
    text-align: center;
    justify-content: center;
}

@media screen and (max-width: 1441px) {
    .pair {
        & svg {
            margin: 0 0.4rem;
        }

        & .label {
            margin-right: 0.4rem;
        }
    }

    .grid {
        column-gap: 0.5rem;
    }

    .container {
        padding-right: 0.7rem;
    }
}
