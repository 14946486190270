.outViewCalendar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  padding: 0 1rem;

  .outViewDateFlex {
    display: flex;
    align-items: center;

    .outViewDateFlexText {
      font-size: 1rem;
      color: #000;
      font-weight: 400;
    }
  }

  .calendatIconView {
    width: 1.0625rem;
    height: 1.0625rem;
    margin-bottom: 5px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.container {
  width: 100%;
  font-family: var(--main-font);
  position: relative;
  height: 26.0625rem;


  .smallPicker {
    position: absolute;
    top: -0.75rem;
    right: 46%;
    width: 1.375rem;
    height: 1.375rem;
    transform: rotate(45deg);
    background-color: #fff;
    border-top: 1px solid #4FB2ED;
    border-left: 1px solid #4FB2ED;
  }

  .titleBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: center;

    .button {
      border: 1px solid #4FB2ED;
      border-radius: 5px;
      padding: 0.3rem 1rem;
    }

    .dateBlock {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 23.75rem;
      border-bottom: 1px solid #e3e3e3;
      padding: 0.5rem;

      .textPeriod {
        color: #707070;
        font-size: 1rem;
      }

      .textDate {
        font-size: 1rem;
        color: #000;
        font-weight: 400;
      }

      .dateValue {
        border: none;
        border-radius: 5px;
        background-color: #f2f2f2;
        display: flex;
        flex-basis: 48%;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0.5625rem 0.75rem 0.8125rem;

        &.activeDate {
          background-color: #4FB2ED;
          color: #fff;

          .textPeriod {
            color: #fff;
          }

          .textDate {
            color: #fff;
          }

          .iconCal {
            color: #fff;
          }
        }
      }
    }

    .monthPickerWrapper {
      margin: 0 1rem;
    }

    .closeButton {
      margin-right: 1rem;
    }

    .actionBlock {
      width: min-content;
      text-align: center;
    }
  }

  .marginBottom {
    justify-content: center;
    margin-bottom: 1rem;
  }

  .actionBlock {
    text-align: center;
    padding: 1rem;
  }
}

.select {
  border: 1px solid #4FB2ED;
  border-radius: 10px;
  font: normal normal 400 1rem var(--main-font);
  position: relative;

  .calendarIcon {
    position: absolute;
    right: 0.5rem;
    top: 0.7rem;
  }

  .optionsBlock {
    top: -0.0625rem;
   right: 50%;
   transform: translateX(50%);
    border-radius: 10px;
    position: absolute;
    background: #fff;
    z-index: 5;
    transition: all 0.3s ease;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    display: none;
    // height: 0;

    &.isOpen {
      display: block;
      margin-top: 4.0625rem;
      height: auto;
      border-right: 1px solid #4FB2ED;
      border-left: 1px solid #4FB2ED;
      border-bottom: 1px solid #4FB2ED;
      border-top: 1px solid #4FB2ED;
    }

    &.isTooRight {
      right: 100%;
    }

    .option {
      border-radius: 0.5rem;
      padding: 0.6rem 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        background: #4FB2ED;
        color: #fff;
      }
    }
  }
}

.estimateItem {
  font: 12px var(--main-font);
  padding: 0.3rem 0.2rem;
  margin: 0.3rem 0;
}

.wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 0.875rem;
}

.btnPosition {
  position: absolute;
  bottom: 0.9375rem;
  left: 50%;
  transform: translateX(-50%);

  button {
    padding: 0.625rem 1.25rem;
    border-radius: 12px;
    font-size: 1.125rem;
  }
}

.label {
  font-weight: 400;
  margin-bottom: 0.3rem;
}