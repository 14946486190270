.legend {
    display: flex;
    align-items: center;
    border-radius: 1.2rem;
    height: 2.4rem;
    background: #fff;
    opacity: 0.9;
    box-shadow: 0 0.19rem 0.38rem rgba(0, 0, 0, 0.08);
    cursor: pointer;
    padding: 0 1rem;
}

.item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-right: 1rem;
    }
}

.indicator {
    margin-right: 0.5rem;
    height: 1rem;
    width: 4px;
    border-width: 1px;
    border-style: solid;
}