.viewContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.calendarOptions {
  left: -25%;
}

.pair {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}

.partial {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.partialLabel,
.partialText {
  font-size: 1rem;
  color: black;
  font-family: var(--title-font);
  word-break: break-all;
}

.partialLabel {
  font-weight: 500;
}

.partialText,
.rememberRepeat {
  font-weight: 400;
}

.bubbles {
  display: flex;
  flex-wrap: wrap;
  gap: 0.53rem;
  margin-bottom: 0.35rem;
}

.bubble {
  border-radius: 0.8rem;
  color: white;
  background-color: var(--color-background-primary);
  padding: 0.5rem 0.66rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.4rem;
}

.rememberRepeat {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  span:first-child {
    margin-right: 0.25rem;
  }
}

.calendarSelect {
  border-color: transparent !important;
}

.range {
  flex: 1;
}

.disabled {
  cursor: default !important;
}