.ruble{
  cursor: default;
  position: relative;
  border-radius: 50%;
  background-color: white;
  color: black;
  font-weight: 500;
  min-width: 1.625rem;
  min-height: 1.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rublePopover{
  width: max-content;
  z-index: 2;
  position: absolute;
  right: 0;
  //height: 20rem;
}

.rublePopoverTitle{
  font-size: 1rem;
  color: #727272;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.rublePopoverContainer{
  font-weight: 400;
  transition: .1s all ease-in-out;
  color: black;
  outline: 1px solid #4FB2ED;
  background-color: white;
  border-radius: .375rem;
  padding: .5rem .625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.rublePopoverItem{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: .25rem 0;
  font-size: .875rem;
  column-gap: 1rem;
  width: 100%;
}

.rublePopoverItemMarker{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: .25rem;
}

.square{
  width: .625rem;
  height: .625rem;
}

.legend{
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-auto-rows: 1fr;
  padding: .25rem 0;
  font-size: .875rem;
  grid-row-gap: .5rem;
  grid-column-gap: 1rem;
  width: 100%;
  & > span{
    text-align: right;
  }
}