.container {
  display: flex;
  border-radius: 5px;
  align-items: center;

  .monthTitle {
    width: 5rem;
    text-align: center;
  }

  .arrowBlock {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 1rem;
    height: 1rem;

    &.arrowBlockRight {
      justify-content: end;
    }

    .arrowLeft {
      transition: all 0.2s ease;
      width: 1rem;
      transform: rotate(90deg);
      cursor: pointer;
    }

    .arrowRight {
      transition: all 0.2s ease;
      width: 1rem;
      transform: rotate(-90deg);
      cursor: pointer;
    }
  }
}

.wrapperFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 19.875rem;
}

.flexFirst {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 14.5625rem;
}

.innerDate {
  display: flex;
  align-items: center;

  .dateText {
    font-size: 1.125rem;
    color: #000;
    font-weight: 400;
  }
}