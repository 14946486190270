.doubleInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    width: 80%;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        width: 1rem;
        height: 1rem;
        left: -1.5rem;
        top: 0;
        //background-image: url("./arrowUp.svg");
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "date label" "worker worker";
    row-gap: 1rem;
    margin-bottom: 1rem;

    & > div:nth-child(3) {
        grid-area: worker;
        margin-right: 0;
    }
}

.accept {
    text-align: right;
    font-weight: 500;
}

.moment {
    color: #707070;
    font-weight: 500;
}

@media screen and (max-width: 1441px) {
    .doubleInfo {
        width: 100%;
    }
}