.container {
    position: relative;
    color: #707070;
    font-weight: 500;
    margin-bottom: 1rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.marker {
    position: absolute;
    width: 2rem;
    display: flex;
    justify-content: center;
    height: 1rem;
    top: 0;
    left: -2rem;

    & svg {
        height: 100%;
    }
}

.row1 {
    width: 100%;
    display: grid;
    grid-template-columns: 9fr 11fr;
    margin-bottom: 0.8rem;
    column-gap: 1rem;

    & svg {
        margin: 0 1rem;
    }
}

.row2 {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 7fr;
    margin-bottom: 0.8rem;
    column-gap: 1rem;
}

.date {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.time {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.label {
    font-weight: 500;
    color: #000;
    margin-right: 1rem;
    white-space: nowrap;
}

.cell {
    display: flex;
    align-items: center;
}

.value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 11rem;
}

.name {
    font-weight: 400;
    color: #000;
}

@media screen and (max-width: 1441px) {
    .value {
        max-width: 7.5rem;
        font-size: 13px;
    }
    .marker{
        left: -1.75rem;
    }
    .label {
        font-size: 13px;
        margin-right: 0.5rem;
    }
}