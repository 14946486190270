.displayedRelation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.875rem 1.25rem;
  font-family: var(--title-font);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-align: left;
  height: auto;
}

.tableRow {
  height: auto;
  margin-bottom: 0.625rem;
  cursor: default !important;
  position: relative;
  &:hover {
    .relationSectionLabel {
      color: white;
    }
    .deleteIcon * {
      stroke: white;
    }
  }
}

.displayedRelationBreak {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-height: 1.25rem;
}

.displayedRelationSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;
  line-height: 1.06;
}

.displayedExpenditureName {
  width: calc(100% - 1rem);
  min-height: 2.5rem;
}


.breakInputContainer {
  width: 3.5rem;
  max-height: 1.25rem;
  position: relative;
}

.breakInput {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
  padding: 0.4rem 0.625rem 0.3rem;
  background-color: white;
  border: none !important;
  border-radius: 0.6rem;
  text-align: right;
}

.relationSectionLabel {
  color: #8e8e8e;
}

.deleteIcon {
  width: 0.75rem;
  height: 0.75rem;
  cursor: pointer;
  position: absolute;
  top: 0.875rem;
  right: 1.25rem;
}
