.name {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.8rem;
}

.value {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 1.5rem;
}