.container {
  background: #f4f4f4;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 1rem;
  height: 3.6rem;
  margin-bottom: 0.8rem;
  font-size: 1.12rem;
  font-family: var(--title-font);

  &:hover {
    background-color: var(--color-background-primary);
    color: var(--color-font-hover);

    .valueName {
      opacity: 1;
    }
    .dot {
      opacity: 0;
    }
    .objectCount {
      color: var(--color-font-hover);
    }
  }

  .dot {
    background: var(--color-background-field8);
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 10rem;
    margin: 0 1rem;
  }

  .name {
    font-family: var(--title-font);
    padding: 0 1rem;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .valueName {
    width: 6%;
    opacity: 0;
    font-weight: 300;
    padding: 0 0.375rem;
    white-space: nowrap;
  }
  .objectCount {
    width: 14%;
    color: var(--color-font-secondary3);
  }
  .value {
    width: 12%;
    text-align: right;
    padding: 0 0.375rem;
    white-space: nowrap;
  }
  .balance {
    @extend .value;
    width: 10%;
    font-weight: 500;
    margin-right: 2rem;
  }
  .divider {
    height: 1.8rem;
    width: 1px;
    background-color: var(--color-devider-main);
    margin: 0 3rem 0 2.18rem;
  }
}
