.pair {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1.25rem;

    &.centered {
        align-items: center;
    }
}

.date {
    display: flex;
    align-items: center;

    & svg {
        margin-left: 0.5rem;
        margin-right: 1rem;
    }
}

.day {
    font-weight: 500;
}

.pass {
    display: flex;
    align-items: center;

    & b {
        flex-basis: 30%;
    }
}

.info {
    display: flex;
    flex-direction: column;

    & b {
        margin-bottom: 0.75rem;
    }
}

////////////////////////////
.justification {
    margin-left: auto;
    margin-right: auto;
    min-width: 10rem;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #4FB2ED;
    background: #E6F4FC;
    height: 1.875rem;
    border-radius: 0.9rem;
    width: fit-content;
    padding: 0 0.5rem;
}

.hr {
    width: 100%;
    height: 1px;
    background: #DBDBDB;
    margin-bottom: 1rem;
}

.mb {
    margin-bottom: 1.25rem;
}

.navbar {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
    width: 100%;

    & span {
        font-size: 1.125rem;
        margin-left: 1.8rem;
    }
}