.modal {
  width: 44rem;
  height: 38rem;
}

.title {
  margin-left: 1.5rem;
  margin-bottom: 0.75rem;
  font-weight: 500;
  font-size: 1.375rem;
}

.subtitle {
  padding: 0.25rem 0.5rem;
  color: #4FB2ED;
  background: #E6F4FC;
  border-radius: 1rem;
  margin-bottom: 0.8rem;
  width: fit-content;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  max-width: calc(100% - 3rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list {
  height: 28.375rem;
  overflow-y: auto;
  padding: 0 1.5rem;
}

.item {
  display: flex;
  background: #F4F4F4;
  border-radius: 1rem;
  align-items: center;
  margin-bottom: 0.375rem;
  height: 3.625rem;
  padding-left: 1.5rem;
}

.itemName {
  width: 70%;
}

.itemCount {
  width: 30%;
  padding-left: 1rem;
  margin-left: 1rem;
  border-left: 1px solid #B9B9B9;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.footer {
  padding-top: 4rem;
  border-top: 1px solid #BCBCBC;
  margin-top: 0.5rem;
}

.placeholder {
  height: 28.375rem;
}