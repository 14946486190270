.container {
  display: flex;
  justify-content: center;
}

.content {
  padding: 1rem 2rem;
  width: 50rem;
  height: 41rem;

  @media screen and (max-width: 1024px) {
    width: 40.625rem;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.title {
  font-size: 1.375rem;
  font-weight: 800;
  margin: 0;
}

.tabBar {
  margin: 1rem 0;
}

.tab {
  width: 11.5rem;
}

.rangeContainer {
  @media screen and (max-width: 1024px) {
    max-width: 28.125rem;
  }
}
