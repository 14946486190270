.head, .row {
  grid-template-columns: 5% 16% 35% 14% 10% 10% 10%;

  & > * {
    flex: 1;
  }
}

.row > * {
  overflow: hidden;
}

.openKsLabel{
  cursor: pointer;
  text-decoration: underline;
}