.relationsTableHeader {
  grid-template-columns: 60% 40%;
  padding: 0 0.5rem;
  height: auto;
}

.relationsTableIntervalBreak {
  justify-content: flex-end;
}

.tableEditIcon {
  height: 0.94rem;
  margin-left: 0.3rem;
  cursor: pointer;
}


.relationsContent {
  position: relative;
  margin-left: -2rem;
  width: calc(100% + 4rem);
  max-height: calc(100vh - 20rem);
  height: calc(100vh - 20rem);
  overflow-y: scroll;
  padding: 0 2rem;
  border-bottom: 1px solid #CBCBCB;
}
