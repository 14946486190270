.sliderClassName {
  width: 34.75rem;

  & > div {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.contentWrapper {
  padding: 2.31rem 1.81rem;
  overflow-y: scroll;
  flex: 1;
}

.content {
  flex: 1;
  padding-bottom: 4rem;
}

.fieldCalendar {
  width: 100%;
}

.fieldRow {
  display: flex;
  margin: 0 -0.38rem;

  .fieldBlock {
    flex: 1;
    margin: 0 0.38rem;
  }
}

.fieldValue {
  color: #707070;
  padding: 0;
  font: normal normal normal 0.9em var(--main-font);
}

.fieldTitle {
  font: normal normal 600 1rem var(--main-font);
  color: #000000;
  margin: 1.5rem 0 0.5rem 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;

  .subTitleButton {
    font: normal normal 400 1rem var(--main-font);
    color: #4fb2ed;
    cursor: pointer;
  }
}
.plusIconBlock {
  display: flex;
  align-items: center;
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font: normal normal bold 1.38em var(--main-font);
    color: #000000;
  }
  .dateTitle {
    color: #000;
    font: normal normal normal 1.13em var(--main-font);
  }
  .userTitle {
    color: #808080;
    font: normal normal normal 1.1em var(--main-font);
  }
}

.blockTitle {
  font: normal normal bold 14px var(--main-font);
  color: #000000;
}

.container {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.actionBlock {
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid #bcbcbc;
  padding: 1.07rem 2.2rem;
  width: 100%;
  bottom: 0;
  text-align: right;
  background-color: #fff;
  border-radius: 0 0 0 20px;
  display: flex;
  justify-content: center;
}

.icon {
  width: 1.5rem;
  cursor: pointer;
  padding: 1rem 0;
  margin-right: 1rem;
}
.classNameSelect {
  border-color: #4fb2ed;
}
.productUsingBlock {
  color: #000;
  margin-top: 1rem;

  .headerProductUsing {
    font-weight: 600;
  }

  .flexRow {
    display: flex;
    padding: 0.5rem 0;
  }
  .field_1 {
    flex: 1;
  }
  .field_2 {
    width: 5rem;
  }
}
.fixedHeightBox {
  min-height: 2.9rem;
}

.select {
  min-height: 2.56rem;
}

.textarea {
  min-height: 2.56rem;
  padding-left: 1.125rem;

  &::placeholder {
    font-size: 1rem;
    font-family: var(--title-font);
  }
}