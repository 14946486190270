.container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000;
    opacity: 0.25;
}

.modal {
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: 560px;
    padding: 19px;
    position: relative;
}

.btns {
    border-top: 1px solid #d3d3d3;
    margin-left: -19px;
    margin-right: -19px;
    padding: 0 19px;
}

.close {
    cursor: pointer;
    position: absolute;
    right: 19px;
    top: 19px;
}

.title {
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
}
