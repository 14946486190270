.container {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.arrow {
  position: absolute;
  left: 0;
}

.switch {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1.5rem;
}

.children {
  padding-left: 1rem;
}