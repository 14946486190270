.container {
  position: relative;
  width: calc(100% - 2px);
  margin: 0 auto;
  height: auto;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: scroll;
  overflow-y: scroll;
  scroll-behavior: initial;
  max-height: 40rem;
  outline: 1px solid #d3d3d3;
  font-size: .8125rem;
}

.worksDoneLabel {
  color: var(--color-font-primary);
  margin-bottom: 0.5rem;
  margin-left: 15%;
  position: relative;
}

.months{
  position: absolute;
  width: 100%;
  max-width: inherit;
  margin-left: 42%;
  height: auto;
  scroll-behavior: initial;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}