.content{
  display: flex;
  flex-direction: column;
  row-gap: .5rem;
}

.row {
  padding: 0.75rem 1rem;
  height: auto;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  position: relative;
  background-color: var(--color-background-field6);
  &:hover {
    cursor: pointer;
    background-color: var(--color-background-primary);
    color: white;
    &:after {
      border-color: transparent transparent transparent white;
    }
  }
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.4625rem 0 0.4625rem 0.4625rem;
    border-color: transparent transparent transparent #969696;
    position: absolute;
    right: 1.22rem;
    top: 50%;
    transform: translateY(-50%);
  }
  & > span {
    word-break: break-word;
  }
}

.objectName {
  font-family: var(--title-font);
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.6875rem;
}

.entity {
  font-size: 0.875rem;
  margin-bottom: 0.56rem;
}

.entityName {
  font-weight: 500;
  font-size: 0.875rem;
}

.objectsHead {
  margin: 0.5rem 0;
  position: sticky;
  top: 5rem;
}

.isHidden{
  display: none;
}

.emptyPlaceholder{
  margin-top: 2rem;
}