.card, .label {
  font-size: 0.875rem;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.469rem 0.656rem;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.688rem 0;
  margin-bottom: 0.688rem;
}

.bold, .label, .buildingName {
  font-weight: 500;
}

.buildingName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.block {
  display: flex;
  height: 2.188rem;
}

.label {
  margin-top: 0.688rem;
}

.blockName {
  width: 6rem;
}