.row {
  grid-template-columns: 5% 2% 40% 15% 8% 8% 7% 10% 5%;

  & > div {
    position: relative;
  }
}

.roundLabel {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50px;

  &.redLabel {
    background-color: #db136f;
  }
}

.nameCell {
  position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}

.title {
  white-space: nowrap;
  width:  calc(100% - 6rem);
  overflow: hidden;
  text-overflow: ellipsis;
}

.devider {
  height: 2rem;
  width: 2px;
  background: #cecece;
  position: absolute;
  right: 1rem;
  top: 0.9rem;
  flex-shrink: 0;
}

.matching {
  margin-left: auto;
  margin-right: 2rem;
  width: 2.5rem;

  & button {
    width: 2rem !important;
    height: 2rem !important;
  }
}

.centered {
  text-align: center;
  padding-right: 2rem;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
}