.input {
    height: 1.786rem;
    padding: 0 0.35714rem 0.35714rem 1.78571rem;
    border-radius: 0 !important;
    border: none !important;
    border-bottom: 1px solid black !important;
}

.submit {
    max-width: 12.85714rem;
    width: 100%;
    margin: 0 auto 2.14286rem;
    min-width: 7.14286rem;
    text-align: center;
    display: flex;
    justify-content: center;
}
