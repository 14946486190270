.container {
  display: flex;
  justify-content: center;

  .innerFlex {
    display: flex;
    justify-content: space-between;
    width: 18.75rem;
  }

  .monthTitle {
    font-size: 1.125rem;
  }

  .dateInfo {
    display: flex;
    align-items: center;
  }
}

.textData {
  font-size: 1.125rem;
  font-weight: 400;
  color: #000;
}

.flexDate {
  display: flex;
  align-items: center;
}

.flexFirstArr {
  display: flex;
  width: 13.75rem;
  justify-content: space-between;
}