.borders {
    border: 1px solid var(--color-border-field18);
    border-radius: 0.357rem;
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-bottom: 0;
    cursor: pointer;
    margin-left: 0.5rem;
}

.checkbox {
    opacity: 0;
    position: absolute;
    //pointer-events: none;
    width: 0;
    height: 0;
    overflow: hidden;
}

.container {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    cursor: pointer;
}