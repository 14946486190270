.sharedBrace {
  z-index: 1;
  width: calc(100% - .22rem);
  height: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-width: 0.125rem;
  border-radius: 0;
  border-style: solid;
  border-right: none;
  border-top: none;
}

.sharedCircle {
  position: absolute;
  top: 0;
  left: -.0625rem;
  transform: translateX(-50%) translateY(-50%);
  height: min-content !important;
  width: min-content !important;
  z-index: 1;
}
