.container {
  padding: 1.625rem 2.3rem;
  border-bottom: 1px solid var(--color-devider-field1);

  .title {
    font: normal normal 400 1.25rem var(--title-font);
    margin-bottom: 1rem;
    margin-right: 1rem;
    // align-self: center;
  }

  .formRow {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 1rem;

    .field {
      margin-right: 1rem;

      label {
        font: normal normal 400 1rem var(--title-font);
        color: #000;
      }
    }

    .input {
      border-color: var(--color-border-primary);
    }
  }

  .formFooter {
    display: flex;
    justify-content: flex-end;
  }

  .addButton {
    padding: 0 1rem;
    color: var(--color-font-secondary5);
    font-family: var(--title-font);
    text-align: right;

    svg {
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}

.showForm {
  display: inline-flex;
  cursor: pointer;
  // align-items: center;

  .arrowDown {
    align-self: flex-start;
    margin-top: 0.2rem;
  }
}