.container {
  padding: 0 2.1rem 1.87rem;

  .searchBlock {
    display: flex;
    align-items: center;
    padding: 1.87rem 0 1.25rem;
    // margin-bottom: 1.25rem;
    position: sticky;
    top: 0;
    background-color: #fff;

    .inputSearchClassName {
      flex: 1;

      &:active,
      &:hover {
        max-width: 100%;
      }
    }
    .addButton {
      padding: 0 1rem;
      color: var(--color-font-secondary5);
      font-family: var(--title-font);
      cursor: pointer;
      svg {
         margin-left: 1rem;
         cursor: pointer;
      }
    }
  }
  .titleTable {
    display: flex;
    align-items: center;
    font: normal normal 400 1.12rem var(--title-font);
    margin-bottom: 1rem;

    .countSection {
      flex: 1;
      text-align: right;
      font-size: 1rem;
    }
    .button {
      margin-right: 1rem;
    }
  }
}
