.title {
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.pair {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

.pairLine {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;

    & > * {
        margin-bottom: 0 !important;
    }
}

.label {
    margin-top: -0.5rem;
    margin-bottom: 0.3rem;
}

.checkbox {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem 0;
}

.img {
    margin-top: 20vh;
}

.spinner {
    position: static;
}

.resultImg {
    margin-top: 6rem;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 4rem;

    & svg {
        width: 4rem;
        height: 4rem;
    }
    & path {
        fill: #4FB1EB;
    }
}

.resultText {
    font-weight: 400;
    width: 100%;
    text-align: center;
}

.comment {
    color: #626262;
    margin-bottom: 1rem;
}

.dangerBtn {
    background-color: #E8527A !important;
}

.declineText {
    text-align: center;
    margin-bottom: 2rem;
}

.declineImg {
    margin-bottom: 1rem;
    margin-top: 2rem;
    height: 11rem;
}

.decline {
    & path {
        fill: #E8527A;
    }
}