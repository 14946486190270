.blockStyles {
  display: flex;
  align-items: center;
  color: #000;

  .labelStyles {
    padding-right: 1rem;
    font: normal normal normal 1rem var(--title-font);
    min-width: 4.375rem;
    text-align: right;
  }

  .containerStyles {
    height: 0.25rem;
    width: 6.2rem;
    background-color: #E6E6E6;
    border-radius: 50px;
    overflow: hidden;


    .fillerStyles {
      height: 100%;
      background-color: #00C3F2;
      border-radius: inherit;
    }
  }
}