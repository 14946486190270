.subtitle {
    margin-top: 0.6rem;
}

.headline {
    border-bottom: none;
    padding-bottom: 0;
    padding-left: 2rem;
}

.field {
    margin-bottom: 1rem;
}