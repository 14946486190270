.block {
  position: absolute;
  top: 6.5rem;
  right: 0;
  display: none;
  font-family: var(--title-font);
  width: 30rem;
  white-space: break-spaces;
  background-color: #fff;
  cursor: default;
  z-index: 99999;

  &.isOpen {
    display: block;
  }
}

.shortly {
  background-color: #fff;
}