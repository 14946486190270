.row {
    grid-template-columns: 8% 10% 24% 24% 10% 16% 8%;
    padding-left: 1.5rem;

    &:hover {
        & .orderLink {
            color: #fff;

            & svg {
                & path {
                    fill: #fff;
                }
            }
        }
    }
}