.tabBar {
  border-radius: 0.5rem;
  padding: 0 0.1rem;
  display: flex;
  background: #EFEFEF;
}

.tab {
  height: 2.3rem;
  display: flex;
  margin: 0.25rem;
  border-radius: 0.5rem;
  font-weight: normal;
  font-size: 1rem;
  cursor: pointer;
  white-space: nowrap;
  justify-content: center;
  width: 9.875rem;
  transition: none !important;
  align-items: center;
}

.active {
  color: #fff;
  background: #4FB2ED;

  &:hover {
    color: #fff;
  }
}

.disabled {
  pointer-events: none;
}