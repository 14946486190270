.relationLocationLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.6rem;
  margin: 1.25rem 0;
  font-family: var(--title-font);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-align: left;
}

.relationLocationIcon {
  height: 1.25rem;
}

.newRelationContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1.125rem;
  margin-bottom: 0.8125rem;
}

.intervalToCheck {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1.25rem;
  cursor: default;
  height: 3rem;
  padding: 0.5rem;
  &:hover {
    color: white;
    background-color: var(--color-background-primary);
    .doubleCheckIcon > path {
      fill: white;
    }
  }
}

.expenditureName {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: auto;
  white-space: nowrap;
}

.blueBg {
  background-color: #eff7fc;
}

.doubleCheckIcon {
  width: 1.5rem;
  min-width: 1.5rem;
  max-width: 1.5rem;
  margin-left: 0.5rem;
}

.checkbox > label {
  background-color: white;
}

.intervals {
  margin-left: -2rem;
  width: calc(100% + 4rem);
}

.selectLabel {
  font-family: var(--title-font);
  font-weight: 400 !important;
}

.addRelationButton {
  width: 15rem;
}