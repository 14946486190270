.container {
  margin-top: 18px;
  margin-bottom: 15px;

  .dayNameBlock {
    display: flex;
    margin: 0 auto;
    width: 17rem;
    flex-wrap: wrap;
  }

  .dayName {
    font-weight: 400;
    color: #000;
    text-align: center;
    font-size: 1rem;
    height: 1.875rem;
    width: 2.42rem;
  }

  .dayNumber {
    font-weight: 400;

    color: #707070;
    text-align: center;
    font-size: 1rem;
    height: 1.875rem;
    width: 2.42rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dayNumber:first-child {
    background-color: #000;
  }

  .holidays {
    color: #4FB2ED;
  }

  .activeDate {
    color: #fff;
    background-color: #4FB2ED;
    border-radius: 4px;
    height: 1.75rem;
    width: 2.42rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}