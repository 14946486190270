.footer {
  width: 100%;
  min-height: 228px;
  padding: 2rem 2.5rem;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.termsContainer {
  display: flex;
  align-items: baseline;
  gap: 1rem;
}

.amountsContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.checkbox {
  color: var(--color-font-secondary1);
  margin-bottom: -1rem;
}

.amountContainer {
  display: flex;
  font: normal normal 500 1.125rem/1.375rem var(--title-font);
}

.amountName, .amount, .amountCurrency {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
}

.amount, .amountCurrency {
  color: var(--color-font-primary);
}

.amount {
  min-width: 9.286rem;
  margin-left: 1.071rem;
  margin-right: 0.5rem;
  align-items: flex-start;
}

.amountRow:not(:last-child) {
  margin-bottom: 0.668rem;
}