.container {
  display: grid;
  grid-template-columns: 8% 32% 13% 10% 13% 8% 16%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  align-items: center;

  & > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.field1 {
  display: flex;
  justify-content: center;
}

.field2 {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
}

.center {
  text-align: center;
}

.field6 {
  text-align: right;
  white-space: nowrap;
  padding-right: 4rem;
}

.dot {
  background: var(--color-background-field8);
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 10rem;
}

@media screen and (max-width: 1640px) {
  .field6 {
    padding-right: 2rem;
  }
}
