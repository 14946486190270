.container {
  display: flex;
  // min-height: 100vh;

  .content {
    width: calc(100% - 4rem);
    background: #fff;
    overflow-x: hidden;
    height: calc(100vh - 4rem);
    overflow-y: scroll;
    //padding: 1.88rem 4.88rem;
  }
}