.container {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
}

.message{
    display: flex;
    align-items: flex-end;
    justify-content: revert;
    max-width: 80%;
    margin-bottom: 0.5rem;
    
    .text{
      background-color: #dbe8eb;
      padding: 0.5rem;
      word-break: break-word;
      border-radius: 5px;
      white-space: pre-wrap;
    }
    .time{
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      color: #5f5f5f;
    }
  }

  .sender{
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
    .senderRole{
      font-weight: normal;
    }
  }

  .dateText{
    text-align: center;
    font-weight: bold;
    color: #737373;
    width: 100%;
    margin-bottom: 0.5rem;
  }