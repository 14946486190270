.close-btn {
    cursor: pointer;
    background-color: #4FB2ED;
    border-top-left-radius: 21px;
    border-bottom-left-radius: 21px;
    position: absolute;
    right: 545px;
    top: 25px;
    z-index: 100000;
    padding: 20px 5px 20px 5px;
}
.modal-children {
  height: 100vh;
  overflow-y: auto;
  position: relative;
  padding-bottom: 80px;
}

.user-page__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

// Accordion
.accordion-enter {
  opacity: 0;
  transform: scale(0.9);
}
.accordion-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.accordion-exit {
  opacity: 1;
}
.accordion-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}


.goodIcon {
  position: absolute !important;
  right: 15px !important;
  top: 54%;
  color: green;
  width: 20px;
  height: 20px;
}

.badIcon {
  position: absolute !important;
  right: 15px !important;
  top: 54%;
  color: #4FB2ED;
  width: 20px;
  height: 20px;
}

.cleave-input {
  height: 2.5625rem;
  outline: none;
  padding: 0.6875rem 1.125rem;
  border-radius: 0.5rem;
  text-transform: capitalize;
  border-radius: 12px;
  border: 1px solid #C3C3C3;

  &:focus {
    opacity: 1;
    border: 1px solid #4FB2ED;
  }
}

@media (max-width: 2501px) {
  div.container {
    max-width: 1550px !important;
  }
}

@media (max-width: 1920px) {
  div.container {
    max-width: 1200px !important;
  }
}

@media (max-width: 1599px) {
  div.container {
    max-width: 1000px !important;
  }
}

