.info {
  width: 100%;
}

.title {
  font: normal normal 500 1.75rem/2.125rem var(--title-font);
  margin-bottom: 0.5rem;
}

.mainInfo {
  font: normal normal 500 1.25rem/1.5rem var(--title-font);
  gap: 0 1.5rem;
  margin-bottom: 1.5rem;
}

.mainInfo, .mainInfoBlock {
  display: flex;
  align-items: center;
}

.mainInfoBlock {
  gap: 0 1rem;
}

.calendarIcon {
  margin-left: 0.75rem;
}

.row {
  display: flex;
  font: normal normal 400 1.125rem/1.375rem var(--title-font);

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.titleRow {
  width: 10.5rem;
  flex-shrink: 0;
}

.columns {
  display: flex;
  align-items: baseline;
  gap: 0 1rem;
}

.column {
  flex: 1;
}

