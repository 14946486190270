@import "src/scss/dimensions";

.container {
  position: relative;
}

.title {
  font: normal normal 500 1.125rem var(--title-font);
  margin: 0 0.688rem 0 0;
}

.mainInfo,
.calendar {
  font: normal normal 500 1.125rem var(--title-font);
}

.titleContainer,
.mainInfo,
.infoBlock {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
}

.infoValue {
  margin: 0 1.125rem;
}

.number {
  width: 6.75rem;
}

.infoColumns {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.provider {
  margin-top: 1.375rem;
  margin-bottom: 1.125rem;
  color: var(--color-font-secondary1);
  text-align: right;
}

.row {
  display: flex;
  align-items: baseline;
  margin-bottom: 0.75rem;
  font: normal normal 300 1.125rem/1.25rem var(--title-font);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.rowTitle {
  width: 12rem;
  margin-right: 2.375rem;
  font: normal normal 400 1.125rem/1.25rem var(--title-font);
  flex-shrink: 0;
}

.col {
  flex: 1;
}

.middleCol {
  margin: 0 2rem;
}

.files {
  width: 24.25rem;
  flex-shrink: 0;
}

.calendar {
  margin: 0.625rem;

  &.disabled {
    border: none;
    padding: 0.7rem;
    margin: 0;
    cursor: default;
  }
}

.downloadButton {
  position: absolute;
  right: 0;
  top: 0;
}

.downloadIcon {
  width: 28px;
  height: 23px;
}
