.tableHeader {
    font: normal normal 500 1rem var(--main-font);
    border-bottom: 1px solid var(--color-background-field7);
    display: grid;
    grid-template-columns: 8% 32% 13% 10% 13% 8% 16%;
    padding-bottom: 0.5rem;

    .dot {
      background: var(--color-background-field8);
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 10rem;
    }

    .rowItem {
      white-space: nowrap;
      display: flex;
      align-items: center;
    }

    .fieldNumber {
      justify-content: center;
    }

    .fieldTitle {
      flex: 1;
    }

    .center {
        justify-content: center;
        text-align: center;
    }
  }