.header {
  display: flex;
  justify-content: space-between;
  padding: 2rem 2.5rem;
}

.chatContainer {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}

.fileBlock {
  width: 24.25rem;
}