.container {
    background-image: url("./bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    flex-direction: column;
    animation: fading 0.3s linear;
    & div {
        height: auto;
    }
}

.imgWrapper {
    width: 8.75rem !important;
    height: 8.74rem !important;
    background: #f2f2f2;
    border-radius: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5.9rem 0;

    & svg {
        width: 4.1rem;
        height: 5.125rem;
    }
}

.img {
    display: block;
    width: 4.1rem;
    height: 5.125rem;
    object-fit: contain;
}

.text {
    font-size: 1.625rem;
    font-weight: 400 !important;
}

.small {
    .imgWrapper {
        width: 4rem !important;
        height: 4rem !important;
        border-radius: 0.75rem;
        margin: 2rem 0;

        & svg {
            width: 2rem;
            height: 2.5rem;
        }

        .img {
            width: 2rem;
            height: 2.5rem;
        }
    }

    .text {
        font-size: 1rem;
    }
}

.btnHolder {
    height: 2.5rem !important;
    width: 1rem;
}

@keyframes fading {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
