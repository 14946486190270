.mark {
    width: 2rem;
    height: 2rem;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
        width: 1.4rem;
    }
}

.content {
    padding: 0.5rem 1rem;
    text-align: center;
    cursor: pointer;
}

.title {
    color: #4fb1eb;
}
