.frame {
  border: none;
  width: inherit;
  height: inherit;

  * {
    scrollbar-color: var(--color-background-primary) #e0e0e0;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    transition: scrollbar-color 0.3s ease-out;
    box-sizing: border-box;
  }

  *::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  *::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 10rem;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--color-background-primary);
    border-radius: 10rem;
    background-clip: content-box;
  }
}
:has(.frame){
  width: 100%;
  height: 100%;
}