.headline {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
}

.counter {
    margin-right: 50px;
}