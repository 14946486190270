.container {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  & :global(.monthSlider) {
    height: 2.813rem;
  }
}

.timeslider {
  margin-left: 1rem;
}

.parametr {
  margin-left: auto;
  white-space: nowrap;
  align-self: center;
}

.tab {
  width: 9.875rem;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1441px) {
  .tab {
    width: 9rem;
  }

  .parametr {
    & span {
      margin-right: 0.5rem;
    }
  }
}