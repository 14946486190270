.container {
  position: relative;

  &:hover {
    .popupContainer {
      display: block;
    }
  }

  .popupContainer {
    display: none;
    position: absolute;
    z-index: 999;
    top: 2.3rem;
    left: -3.59rem;
    background-color:#fff;
    border: 1px solid #4FB2ED;
    border-radius: 0.5rem;
    width: 23.75rem;
    padding: 1rem 1.5rem;

    .arrow {
      position: absolute;
      top: -0.54rem;
      left: 16%;
      width: 1rem;
      height: 1rem;
      border-left: 1px solid var(--color-border-primary);
      border-top: 1px solid var(--color-border-primary);
      transform: rotate(45deg);
      background: #fff;

      &:before {
        content: "";
        position: absolute;
        width: 800%;
        height: 800%;
        top: -400%;
        left: -400%;
      }
    }

    .title {
      font: normal normal 400 1.37rem var(--title-font);
      color: #000;
      text-align: center;
      margin: 0 0.5rem;
      margin-bottom: 0.56rem;
    }

    .button {
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }

  .infoCercleBlock {
    display: flex;
    margin-bottom: 2rem;

    .cercle {
      width: 50%;

      .circleInner {
        width: 8rem;
      }
    }

    .legendBlock {
      width: 50%;

      .legend {
        display: flex;
        align-items: center;
        font: normal normal 400 0.75rem var(--title-font);
        margin-bottom: 0.56rem;

        .projectBlock {
          width: 1rem;
          flex-shrink: 0 !important;
          height: 0.56rem;
          margin-right: 0.2rem;

          &.doneStatus {
            background-color: #8FE532;
          }

          &.acceptedStatus {
            background-color: #FFE86A;
          }

          &.toPayStatus {
            background-color: #72E2E6;
          }
        }
      }
    }
  }
  .titleRow {
    display: flex;

    .titleItemRow {
      width: 25%;
      font: normal normal 400 0.75rem var(--title-font);
      color: #727272;
    }
    .number {
      width: 3rem;
    }
    .name {
      flex: 1;
    }
  }
  .dataRow {
    display: flex;

    .dataItemRow {
      width: 25%;
      font: normal normal 400 0.87rem var(--title-font);
      color: #000;
    }

    .number {
      width: 3rem;
    }

    .name {
      flex: 1;
    }
  }
}