.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    margin-bottom: 0.75rem;
    padding: 1rem 1.3rem;
    border-radius: 0.75rem;
    border: 1px solid #4FB2ED;
    grid-template-areas: "name name" "info1 info2";
    position: relative;
    max-width: 32rem;
    column-gap: 0.5rem;
}

.name {
    grid-area: name;
    font-weight: 500;
    margin-bottom: 0.75rem;
    width: calc(100% - 2.6rem);
    overflow: hidden;
    text-overflow: ellipsis;
}

.info {
    display: flex;
    align-items: center;
    white-space: nowrap;

    & b {
        display: block;
        font-weight: 500;
        margin-right: 0.6rem;
    }
}

.status {
    font-weight: 800;
    font-size: 0.75rem;
    color: #fff;
    height: 1.4rem;
    padding: 0 0.7rem 0 0.25rem;
    border-radius: 0.7rem;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 1.5rem;
    transform: translateY(-50%);

    &.green {
        background: #6FC79B;
    }

    &.blue {
        background: #4FB1EB;
    }

    &.gray {
        background: #BABABA;
    }

    & svg {
        margin-right: 0.5rem;
    }
}