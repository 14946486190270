.container {
  position: relative;
  z-index: 10;
  margin-bottom: 1rem;
  top: 0;
}

.content {
  align-items: center;
  background-color: #fff;
}

.circleTitle {
  text-align: left !important;
}

.all {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  min-width: 8rem;
}

.allCount {
  font-size: 1.5rem;
}