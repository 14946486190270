.container {
  .header {
    height: 3.625rem;
    padding: 0 1.44rem;
    font: normal normal 400 1.12rem var(--title-font);
    background-color: var(--color-background-field6);
    display: flex;
    align-items: center;
    // justify-content: space-between;
    border-radius: 0.8rem;
    margin-bottom: 0.5rem;
    cursor: pointer;

    &:hover,
    &.active {
      color: var(--color-font-hover);
      background-color: var(--color-background-primary);
      font-weight: 400;
      
      .materialTitle {
        display: inline;
        font-weight: 400;
      }
    }
    // &.active {
    //   color: var(--color-font-hover);
    //   background-color: var(--color-background-primary);
    //   font-weight: 400;
    // }

    .title {
      flex: 1;
    }
    .count { 
      padding: 0 1rem;
      font-weight: 500;
    }
    .materialTitle {
      display: none;
    }
  }
}