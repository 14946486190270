.controls {
    width: calc(100% - 7px);
    position: fixed;
    background-color: #fff;
    bottom: 0;
    left: 0;
    border-top: 1px solid #bcbcbc;
    display: flex;
    padding: 1.07rem 0;
    border-radius: 0 0 20px 20px;
    justify-content: center;
    font-weight: 400;
  
    & strong {
      margin-left: 0.5rem;
    }
  }
  
  .controlsWrapper {
    padding-top: 4rem;
  }
  
  .doubleBtns {
    column-gap: 1rem;

    & button {
      flex-basis: 35%;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }