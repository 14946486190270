.tables {
  border-collapse: separate;
  border-spacing: 0 0.3rem;
  width: 100%;

  td {
    padding: 0 1rem;
  }

  th {
    padding: 0 1rem;
  }

  tr th {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  tr td:last-child {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  tr td:first-child {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  thead {
    position: sticky;
    top: 2.7rem;
    z-index: 2;
    background-color: #fff;

    & th {
      border-radius: 0 !important;
      padding-top: 1rem;
      border-bottom: 1px solid #4FB2ED;
    }
  }
}

.tableRowClass {
  background-color: #f4f4f4;
  color: #000;
  font-size: 13px;
  font-weight: 400;
  height: 3.875rem;
  margin: 5px;
  font-family: var(--title-font);

  & :nth-child(5) {
    @extend :nth-child(2);
  }

  & :nth-child(6) {
    @extend :nth-child(2);
  }

  &:hover {
    background-color: #4FB2ED;
    color: #fff;

    &:hover svg > path {
      fill: #fff;
    }
  }

  & :nth-child(2):hover {
    overflow: visible;
    white-space: normal;
    height: auto;
  }
}

.tableHeaderClass {
  font-family: var(--title-font);
  font-size: 1rem;
  cursor: pointer;
  color: #000;
  font-weight: 500;
  white-space: nowrap;

  .crossIcon {
    margin-left: 0.5rem;
    padding: 2px;
    width: 1.9rem;
  }
}

.icon {
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.5rem;
  }
}

.titleBlockSum {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  padding-right: 2rem;

  .title {
    margin-right: 0.5rem;
  }
}

.titleBlock {
  display: flex;
  align-items: center;
  justify-content: start;
  white-space: nowrap;

  .title {
    margin-right: 0.5rem;
  }
}

.MoreButton {
  margin-top: 2rem;
}

.stickyTabs {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 3;
}

.overFlowLine {
  background: #fff;
  width: 90%;
  height: 1.88rem;
  position: absolute;
  left: 51%;
  transform: translateX(-50%);
  top: 3.7rem;
  z-index: 10;
}