@import "src/scss/dimensions";

.container {
  height: 100%;
}

.header {
  margin-bottom: 1.4rem;
  display: flex;
}

.slider {
  margin: 0 1rem;
}

.addButton {
  margin-left: auto;
}

.slider, .addButton, .tabs {
  height: 2.813rem;
}

@include breakpoint-to($laptop) {
  .addButton {
    font-size: 1rem;
  }
}