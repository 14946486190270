.sectionRow {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 3rem;
  margin-bottom: 0.625rem;
  height: 3.625rem;
  position: relative;
}

.primary {
  background-color: var(--color-background-field6);

  .addBtn {
    display: none;

    :global(.addBtnBg) {
      fill: #fff;
    }

    :global(.addBtnCross) {
      fill: #000;
    }
  }

  &:hover {
    background-color: var(--color-background-primary);

    .addBtn {
      display: block;
    }

    .checkbox {
      border-color: #fff;

      path {
        stroke: var(--color-font-hover);
      }
    }

    .actionBlock {
      display: flex;
    }

    &, .budgetTitle, .input, .input:hover, .input::placeholder, .progressBar {
      color: var(--color-font-hover);
    }

    .input, .input:hover {
      border: 1px solid var(--color-font-hover);
    }

    .mark, .info, .progressBarFiller {
      background-color: var(--color-font-hover);
    }

    .info path {
      fill: #000;
    }

    .arrowIcon path {
      fill: var(--color-font-hover);
    }
  }
}

.checkboxContainer {
  margin-right: 0.75rem;
}

.inputContainer {
  width: 100%;
}

.title {
  font: normal normal 1rem var(--title-font);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  margin-left: 1rem;
  border: 1px solid rgba(0, 0, 0, 0);
}

.input {
  color: #000;
  border: 1px solid var(--color-border-primary);
  transition: none;
}

.bigBtn {
  border-radius: 0.5rem;
  border-color: #FFFFFF;
  background-color: var(--color-background-main);
  width: 16.7rem;
  height: 3rem;
  padding: 0 0.8rem 0 1.35rem;
  font: normal normal 500 1.12rem var(--title-font);
  color: var(--color-font-secondary4);
  cursor: pointer;

  .buttonContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  width: 35%;
}

.confirmSelect {
  margin-left: 1rem;
}

.actions {
  display: flex;
}

.actionBlock {
  display: none;
}

.budgetCol {
  display: flex;
  justify-content: flex-end;
}

.divider {
  height: 2.25rem;
  width: 1px;
  background-color: #C9C9C9;
  margin: 0 1rem;
  flex-shrink: 0;
}

.infoContainer {
  margin: 0 1.625rem;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4FB2ED;
  border-radius: 50%;
  width: 1.625rem;
  height: 1.625rem;
  cursor: pointer;

  & path {
    fill: #fff;
  }
}

.arrowIcon {
  position: absolute;
  right: 1.125rem;
  width: 1rem;
  height: 0.5rem;

  path {
    fill: #969696;
  }
}

.leftSide {
  display: flex;
  align-items: center;
  width: 65%;
  justify-content: flex-end;
}

.mark {
  background-color: #C1E7FF;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  color: #000;
  font-weight: 500;
  margin-left: 1.625rem;
}

.budgetTitle {
  width: 10rem;
}