@use "../../../../_ks6a_vars.scss" as vars;

.works {
  min-height: inherit;
  background-color: white;
  position: sticky;
  left: 0;
  z-index: 20;
  border-right: vars.$primaryBorder;
  overflow-y: visible;
  min-width: 42%;
}

.worksHeader,
.worksRow,
.total {
  & > div {
    outline: vars.$cellBorder;
    display: flex;
    padding: 0.5rem;
  }
}

.worksHeader {
  font-weight: 500;
  font-size: 0.875rem;
  position: sticky;
  top: 0;
  background-color: white;
  height: vars.$headerHeight;
  display: grid;
  grid-template-columns: vars.$worksColumns;
  grid-template-rows: repeat(2, 1fr);
  z-index: 10;
  & > div {
    align-items: center;
  }
  &::after {
    content: " ";
    display: block;
    background-color: #f4f4f4;
    height: 1.625rem;
    grid-column: 1/4;
    border-top: vars.$cellBorder;
    border-bottom: vars.$cellBorder;
  }
}

.worksNumber {
  grid-column: 1 / 3;
  grid-row: 1;
  justify-content: center;
}

.worksByOrderNumber {
  grid-column: 1;
  grid-row: 2;
}

.worksByEstimateNumber {
  grid-column: 2;
  grid-row: 2;
}

.worksElements {
  grid-column: 3;
  grid-row: 1 / 3;
}

.worksRow {
  display: grid;
  grid-template-columns: vars.$worksColumns;
  grid-template-rows: vars.$baseRowHeight;
  & > div {
    align-items: center;
  }
}

.worksRowByOrderNumber {
  grid-column: 1;
  justify-content: center;
}

.worksRowByEstimateNumber {
  grid-column: 2;
  justify-content: center;
}

.worksRowElement {
  grid-column: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.worksRowElement > span {
  max-height: 100%;
  display: block;
  overflow: hidden;
}

.totalWrapper{
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: vars.$primaryBorder;
  height: auto;
  display: flex;
  justify-content: flex-end;
}

.total {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: vars.$totalRows;
  width: max-content;
  & > div {
    align-items: center;
    padding: 0 1.5rem;
  }
}

.totalLabel,
.otherLabel,
.sumLabel {
  font-weight: 700;
  justify-content: flex-end;
  grid-column: 1;
  font-size: 1rem;
}

.totalCosts,
.otherCosts,
.sumCosts {
  justify-content: flex-end;
  grid-column: 2;
}

.totalLabel,
.totalCosts {
  grid-row: 1;
}

.otherLabel,
.otherCosts {
  grid-row: 2;
}

.sumLabel,
.sumCosts {
  grid-row: 3;
}
