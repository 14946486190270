.container {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #e0e0e0;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.avatar {
  width: 20px;
  height: 40px;
  border-radius: 100%;
}

.popup {
  position: absolute;
  bottom: -95px;
  right: 0;
  background-color: #fff;
  color: #000000;
  border: 1px solid #e5e5e5;
  width: 218px;
  height: 93px;
  padding: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  border-radius: 8px;
  z-index: 100;

  .item {
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s ease;
    transition-property: color, background;
    white-space: nowrap;
    width: 100%;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 7px;
    &:hover {
      background: #4fb2ed;
      color: #fff;
    }
  }
}
