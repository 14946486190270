.daysWrapper {
  position: sticky;
  top: 0;
  z-index: 97;
  margin-left: 35%;
}

.dayWrapper {
  line-height: 1.75rem;
  height: 2.25rem;
  position: absolute;
  top: 0;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(177, 177, 177, 1)
}

.weekend {
  color: #4FB2ED;
}

.today {
  .day {
    width: 1.75rem;
    height: 1.75rem;
    color: #fff;
    background-color: #4FB2ED;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}