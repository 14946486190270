.writeHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 2.8rem;
  background-color: #fff;
  padding-top: 1rem;
  z-index: 3;
  font-size: 13px !important;

  .headerSelect {
    display: flex;
    align-items: center;

    .headerText {
      margin-right: 1rem;
    }
  }

  .headerText {
    font-size: 1rem;
    color: #000;
    font-weight: normal;
  }

  .headerText1 {
    flex-basis: 22%;
  }

  .headerSelect1 {
    .headerText {
      white-space: nowrap;
    }

    .chosenSelect {
      width: 16.9375rem;
    }
  }

  .headerSelect2 {
    margin-left: 1rem;
    margin-right: 1rem;

    .getterSelect {
      width: 25.9375rem;
    }
  }
}

.table {
  border-collapse: separate;
  border-spacing: 0;

  thead {
    position: sticky;
    top: 6.25rem;
    z-index: 2;

    & th {
      font: normal normal 500 1rem var(--title-font) !important;
      background-color: #fff;

      & > div {
        font: normal normal 500 1rem var(--title-font) !important;
      }
    }
  }

  &.withoutUtilization {
    & thead {
      top: 2.6rem;
    }
  }

  td {
    padding: 0.5rem 1rem;
  }

  th {
    padding: 1rem;
    padding-bottom: 0;
    border-bottom: 1px solid var(--color-background-primary) !important;
  }

  & th:nth-child(3) {
    text-align: left;
  }

  .plusIcon {
    cursor: pointer;
  }
}

.tableRowClass {
  text-align: center;

  & :nth-child(3) {
    text-align: left;
  }
}

.tableHeaderClass {
  font: normal normal 500 1rem var(--main-font);
  cursor: pointer;
  color: #454545;
  white-space: nowrap;
  border-bottom: 1px solid var(--color-border-field4);
}

.number {
  display: flex;
  align-items: center;
  font-size: 13px;

  .title {
    margin-right: 0.4rem;
  }

  .center {
    width: 100%;
    text-align: center;
  }
}

.numberValue {
  font-size: 1.125rem;
}

.countInput {
  border: none;
  background-color: darken(white, 5%);
  text-align: center;

  &:disabled {
    background-color: transparent;
  }

  &:focus {
    background-color: darken(white, 10%);
  }
}

.classNameSelect {
  width: 15rem;
}

.smetaSize {
  margin: 0 auto;
}