.container {
  width: 72rem;
  height: 37.5vh;

  .block1 {
    padding: 1.5rem;
  }

  .block1Title {
    display: flex;
    justify-content: space-between;

    span {
      font: normal normal 400 1.375rem var(--main-font);

    }
  }


  .block1btn {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.tableHeaderClass {
  font: normal normal 500 1rem var(--main-font);
  color: black;
}

.tableRowClass {
  font: normal normal 400 1rem var(--title-font);
  color: black;

  & td:first-child {
    padding-left: 1rem;
  }

  & td:last-child {
    padding-right: 1rem;
  }

}

.errorName {
  margin: 0.5rem 0;
}

.download {
  text-decoration: none;
}

.closeIcon {
  cursor: pointer;
}

.sendBtn {
  width: 10rem;
  padding: 6px 20px;
  line-height: 1rem;
  height: 2.5rem;
  font-size: 1.12rem;
  font-weight: 400;
  background-color: #4FB2ED;
  color: #FFFFFF;
  margin-right: 1rem;

  &:hover {
    background-color: #4FB2ED;
    color: #FFFFFF;
  }
}


.tableTextBold {
  font-size: 1rem;
  color: #000;
  font-weight: 500;
}