.rangeElement{
  display: flex;
  flex-direction: column;
  row-gap: .375rem;
  padding: 0 .25rem;
}

.label{
  font-size: 1rem;
  font-weight: 500;
}

.indicator{
  position: relative;
  background-color: white;
  width: 1.6875rem;
  height: 1.6875rem;
  border-radius: 50%;
  box-shadow: 0 .15rem .5rem #cbcbcb;
  z-index: 2;
  cursor: grab;
  &:active{
    cursor: grabbing;
  }
}

.line{
  z-index: 0;
  position: relative;
  cursor: pointer;
  &:after{
    content: ' ';
    display: block;
    background-image: linear-gradient(90deg, #00FF6C 1.6%, #FA890E 53.2%, #FF0027 98.6%);
    transform: translateY(-50%);
    width: 100%;
    height: .625rem;
    border-radius: .5rem;
    position: absolute;
    top: 50%;
  }
}

.legend{
  display: flex;
  justify-content: space-between;
  width: calc(100% - 1rem);
  margin: 0 .25rem;
}

.priority{
  width: .25rem;
  height: .6875rem;
  border-radius: .125rem;
}

.priorityLow{
  outline: 1px dashed #54D74C
}

.priorityMedium{
  outline: 1px solid #EE7E0E;
}

.priorityHigh{
  background-color: #FF0127;
  outline: 1px solid #FF0127;
}

.disabled, .disabled:active{
  cursor: default;
}