.slider {
  width: 58rem;
}
.container {
  overflow: auto;
  height: 100vh;

  .header {
    padding: 1.3rem 0 1.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--color-devider-field1);
  }
  .content {
    // padding: 1.87rem 2.1rem;
  }
  // .bottom {
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   height: 4rem;
  //   background-color: var(--color-devider-field);
  //   width: 100%;
  // }
}

.tab {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}