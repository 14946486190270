@import "src/scss/dimensions";

.container {
  padding: 0.2rem 0.1rem;
  position: relative;
  width: 100%;
  height: 8rem;

  &:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }

  .content {
    padding: 1rem;
    height: 100%;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;

    & > * {
      flex-shrink: 0;
    }

    & > *:not(:last-child):not(.actionsCol) {
      padding-right: 1rem;
    }

    &:hover, &.active {
      background-color: #f8f8f8;
    }

    &:hover .addIntervalCol {
      opacity: 1;
    }
  }
}

.numberCol {
  width: 3%;
  align-self: center;
  text-align: center;
}

.number {
  margin-bottom: 0.25rem;
}

.infoCol {
  width: 37%;
}

.countCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.priceCol, .countCol {
  width: 10%;
}

.intervalCol {
  width: 21%;
  position: relative;
}

.actionsCol {
  width: 8%;
  display: flex;
  justify-content: center;
}

.collapseBtn {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: -8px;
  width: 5rem;
  height: 16px;
  background-color: #4FB2ED;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  z-index: 2;
}

.expanded {
  height: auto;
}

.handlerDraft {
  .infoCol {
    width: 32%;
  }
  .intervalCol {
    width: 17%;
  }
}

.plan, .progress {
  .intervalCol {
    width: 15%;
  }
}

.infoContainer {
  margin-top: 0.5rem;
}

.addIntervalCol {
  width: 3%;
  opacity: 0;
}

.addIntervalIcon {
  cursor: pointer;
}

.relatedWork {
  margin-right: -33%;
  width: 33%;
}