.difference {
  display: inline-block;
  white-space: nowrap;
  font: normal normal 500 1rem/1.25rem var(--title-font);

  .diffDenominator {
    border-top: 1px solid var(--color-border-field4);
    padding-top: 0.357rem;
    margin-top: 0.357rem;
  }

  .nullValue {
    text-align: center;
  }

  .negative {
    color: var(--color-negative);
  }

  .positive {
    color: var(--color-positive);
  }
}