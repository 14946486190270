.container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    color: #fff;
    font-size: 0.9rem;
    background: #4FB2ED;
    border-radius: 4px;
    cursor: pointer;

    & svg {
        margin-left: 0.4rem;
        margin-right: 0.8rem;
    }

    & input {
        position: fixed;
        opacity: 0;
        z-index: -100;
        pointer-events: none;
    }
}