.input {
  height: 2.5625rem;
  outline: none;
  padding: 0.6875rem 1.125rem;
  font-size: 1rem;
  border-radius: 0.75rem;
  border: 1px solid var(--color-border-field7);

  &:focus {
    opacity: 1;
    border: 1px solid var(--color-font-primary);
  }
}