.container {
  .btnPosition {
    display: flex;
  }

  .title {
    font-size: 1.71rem;
    font-family: var(--title-font);
    font-weight: 500;
    color: #000;
  }

  .actionBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0;

    .dateBlock {
      display: flex;
      background-color: #f4f4f4;
      padding: 0 0.4rem;
      border-radius: 10px;
    }
  }

  .devider {
    height: 2rem;
    margin-left: 1rem;
    width: 2px;
    background: #cecece;
  }

  .cellBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tableClass {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;

    tr:before {
      content: " ";
      position: absolute;
    }
  }

  .headerClass {
    font-family: var(--main-font);
    color: #000;
    font-size: 16px;
    font-weight: 500;
    padding: 0 1rem;
    margin-left: 1rem;
  }

  .rowClass {
    height: 60px;
    font-size: 1em;
    font-family: var(--title-font);
    margin-bottom: 4px;
    position: relative;

    .notEditable:before {
      content: " ";
      position: absolute;
      top: 0.8rem;
      left: -1rem;
      height: 2.5rem;
      width: 4px;
      background-color: #ffa700;
      border-radius: 10px;
    }

    td {
      background-color: #f4f4f4;

      &:first-child {
        border-radius: 10px 0 0 10px;
      }

      &:last-child {
        border-radius: 0 10px 10px 0;
      }
    }
  }
  
  .cellClass {
    padding: 0 1rem;
    font-size: 18px;
    font-weight: 400;
  }

  .titleClass {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 15rem;
  }

  .inputSum {
    width: 63%;
    margin-right: 1rem;
    background-color: #fff;
    border: none;
  }

  .textCenter {
    text-align: center;
  }
}

.tableBlock {
  .header {
    display: flex;
    font: normal normal 500 1rem var(--main-font);
    color: #000;
    padding: 0.5rem 0rem;
    position: sticky;
    background-color: #fff;
    z-index: 3;
    top: 3rem;
    border-bottom: 1px solid var(--color-background-primary);
    margin-bottom: 0.3rem;

    &::before {
      content: "";
      position: absolute;
      background-color: #fff;
      left: -2rem;
      width: 2rem;
      height: 100%;
      top: 0;
    }

    .headerItem {
      flex-basis: 39%;
      padding: 0 0.5rem;
      display: flex;
      justify-content: space-between;
    }

    .amountHeader {
      flex-basis: 13%;
      width: 13%;
    }

    .name {
      flex-basis: 41%;
      width: 41%;
      padding-left: 1.3rem;
    }
    .checkBlock {
      flex-basis: 20%;
    }
  }
}
