.wrapper {
  height: 19rem;
  width: 20rem;
  display: flex;
  padding-top: 1.5rem;
  justify-content: center;
  position: relative;

  .smallPicker {
    position: absolute;
    top: -0.75rem;
    right: 46%;
    width: 1.375rem;
    height: 1.375rem;
    transform: rotate(45deg);
    background-color: #fff;
    border-top: 1px solid #4FB2ED;
    border-left: 1px solid #4FB2ED;
  }

  .button {
    background-color: #E5E5E5;
    border-radius: 5px;
    padding: 0.3rem 1rem;
  }

  .dateValue {
    border: 1px solid #808080;
    padding: 0.3rem 1rem;
    border-radius: 5px;
  }

  .marginBottom {
    margin-bottom: 14px;
  }

  .actionBlock {
    position: absolute;
    bottom: 19px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.select {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.38rem;
  border: 1px solid #4FB2ED;
  border-radius: 0.5rem;
  padding: 0.7rem 1rem;
  font: 1rem var(--main-font);
  position: relative;
  background: #fff;
  cursor: pointer;

  .calendarIcon {
    margin-left: auto;
  }

  .title {
    margin-right: 1rem;
  }

  .optionsBlock {
    top: 3rem;
    border-radius: 12px;
    position: absolute;
    background: #fff;
    z-index: 9999;
    transition: all 0.3s ease;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    display: none;
    height: 0;
    cursor: auto;

    &.isOpen {
      display: block;
      height: auto;
      border-right: 1px solid #4FB2ED;
      border-left: 1px solid #4FB2ED;
      border-bottom: 1px solid #4FB2ED;
      border-top: 1px solid #4FB2ED;
      margin-top: 10px;
    }

    .option {
      border-radius: 0.5rem;
      padding: 0.6rem 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;


      &:hover {
        background: #4FB2ED;
        color: #fff;
      }
    }
  }
}

.estimateItem {
  font: 0.75rem var(--main-font);
  padding: 0.3rem 0.2rem;
  margin: 0.3rem 0;
}

.flexClass {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.label {
  color: #666666;
  margin-bottom: 0.35rem;
}

.hidden {
  display: none;
}