.template {
  overflow-y: hidden !important;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content {
  display: flex;
  flex: 1;
}

.tree {
  width: 35%;
  position: sticky;
  left: 0;
  z-index: 97;
  background-color: white;
  min-height: calc(100% + 5.81rem);
  &:before {
    content: "";
    display: flex;
    background-color: #fff;
    width: 100%;
    height: 2.81rem;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

.controls {
  display: flex;
  height: auto;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
  column-gap: 0.5rem;
  padding: 1.88rem 4.88rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 98;
  background: #fff;
}

.bubbleRuble,
.bubbleInfo {
  display: none;
}

.branch {
  position: absolute;
  width: 100%;
  background-color: #fff;
  border-radius: 0.625rem;
  height: 3rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  box-sizing: border-box;
  padding: 0 0.625rem 0 1rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  &:hover {
    z-index: 1;
    align-items: flex-start;
    height: auto;
    min-height: 3rem;
    padding-top: 0.765rem;
    padding-bottom: 0.765rem;
    .branchName {
      white-space: normal;
    }
  }
}

.branchChild {
  padding-left: 2rem;
}

.branchExpenditure {
  padding-left: 4rem;
}

.branchName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.branchBubbles {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > div:not(:last-child) {
    margin-right: 0.75rem;
  }
}

.branch:nth-child(odd) {
  background-color: #eff7fc;
}

.activeBranch,
.branch:hover {
  background-color: #4fb2ed !important;
  color: #fff !important;
  border-radius: 0.625rem 0 0 0.625rem;

  .count {
    background-color: #fff;
    color: #4fb2ed;
  }

  .branchCheckbox {
    border-color: white;
  }
  .branchCheckbox.branchCheckboxFalse:before,
  .branchCheckbox.branchCheckboxFalse:after {
    background-color: white;
  }
  .bubbleRuble,
  .bubbleInfo {
    display: block;
  }
}

.branchCheckbox {
  min-width: 0.875rem;
  width: 0.875rem;
  height: 0.875rem;
  border: 1px solid #d1d1d1;
  border-radius: 0.25rem;
  margin-right: 1rem;
  position: relative;
  cursor: pointer;
}

.branchCheckbox.branchCheckboxTrue::after {
  content: "";
  position: absolute;
  width: 0.0875rem;
  height: 0.5rem;
  background-color: #7c7c7c;
  top: 0.125rem;
  left: calc(50% - 0.04375rem);
}

.branchCheckbox.branchCheckboxFalse:before {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 0.0875rem;
  background-color: #7c7c7c;
  top: calc(50% - 0.04375rem);
  left: 0.125rem;
}

.branchChildActive {
  color: #4fb2ed;
}

.datePicker {
  height: 2.813rem;
  & > div:first-child {
    width: max-content;
  }
}

.dateBlock {
  display: flex;
  background-color: #f4f4f4;
  padding: 0 0.4rem;
  border-radius: 0.625rem;
}

.calendar {
  position: relative;
  width: 100%;
  scroll-behavior: auto;
  overflow-x: scroll;
  padding-bottom: 3rem;
  min-height: calc(100vh - 9.39rem);
  max-height: calc(100vh - 9.39rem);
  overflow-y: scroll;
}

.dayWeekend {
  top: 2.25rem;
  position: absolute;
  height: 100%;
  background-color: #ebebeb;
}

.dayWork {
  position: absolute;
  height: 100%;
  top: 2.25rem;
  border-right: 1px dashed #dedede;
}

.legendaWrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.legenda {
  position: fixed;
  bottom: 1.56rem;
  display: flex;
  justify-content: center;
  z-index: 98;
  margin-left: 25%;
}

.diagramActionsWrapper {
  position: fixed;
  top: 13rem;
  right: 2.2rem;
  display: flex;
  justify-content: center;
  z-index: 98;
}

.count {
  background: #4fb2ed;
  min-width: 2.25rem;
  padding: 0 0.375rem;
  height: 1.375rem;
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  border-radius: 0.69rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.branchNameWrapper {
  width: calc(100% - 4.5rem);
  display: flex;
  align-items: center;
  cursor: default;
}

@media screen and (min-width: 1920px) {
  .treeHead {
    width: calc(35% - 0.5rem);
  }
  .tab {
    width: 9.8rem;
  }
}

.weeksLineControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 100%;
  width: calc(65% - 1rem);
  height: 2.81rem;
  right: 0.5rem;
}

.weeksLineButton {
  width: 1.875rem;
  height: 1.875rem;
  padding: 0.475rem;
  background-color: #dedede;
  &:first-child {
    padding-left: 0.416rem;
  }
  &:last-child {
    padding-right: 0.416rem;
  }
}

.runningLine {
  background-color: rgba(216, 241, 255, 0.5);
  z-index: 0;
  height: 3rem;
  position: absolute;
  margin-top: 2.81rem;
}

.weeksLine,
.daysLine {
  position: absolute;
  top: 0;
  margin-left: 35%;
  width: 100%;
  z-index: 0;
  min-height: 100%;
}

.viewTabs > div {
  padding: 0.3125rem 0.75rem;
  width: min-content;
}

.weekBackground {
  position: absolute;
  top: 2.81rem;
  width: auto;
  min-height: 100%;
}

.currentLine {
  position: absolute;
  width: 0.1875rem;
  min-height: 100%;
  top: 2.81rem;
  background-color: #707070;
  z-index: 0;
  transform: translateX(50%);
}

.currentDay {
  position: absolute;
  width: 0.1875rem;
  min-height: 100%;
  top: 2.25rem;
  background-color: #707070;
  z-index: 0;
  transform: translateX(-50%);
}
