.link {
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
  transition: none;
  display: inline;

  &, &:hover {
    color: var(--color-font-main);
  }

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid;
    transition: border-bottom-color 0.25s;
  }

  .icon {
    margin: 0 0.5rem;

    & svg {
      display: inline;
    }
  }

  &.primary .title {
    border-bottom-color: var(--color-border-primary);
  }

  &.secondary .title {
    border-bottom-color: var(--color-font2);
  }

  &.tertiary .title {
    border: none;
  }
}