* {
  font-family: 'Montserrat', sans-serif;
}

.eventsListEmptyPlaceholder{
  margin-top: 1.5rem;
}


.container {
  background-color: #fff;
}

.container {
  margin-top: 13rem;
}

// ==========

.eventsWithDataRange {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectedBlock {
  display: flex;
  align-items: center;
}

.selectedItem {
  padding: 8.35px 12.68px 9.65px 9.5px;
  background-color: #4FB2ED;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  margin-bottom: 9.5px;
  margin-right: 9.5px;

  .selectedItemText {
    font-size: 1rem;
    font-weight: 400;
    margin-right: 27px;
  }
}

.fixedHeader {
  width: 100%;
  padding: 0 1.813rem 0 1.75rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111111;
}

.positionTypesEvents {
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 10rem;
  left: 0;
  z-index: 1;
  background: white;
}

#eventsSelect div:nth-child(1) {
  pointer-events: none !important;
}

.calendarRange {
  height: 2.625rem;
  font-size: 0.75rem;
  // margin-top: 0.75rem;
  flex-basis: 70%;
}

.headerContent {
  width: 100%;
  padding-top: 25px;
  margin-bottom: 15.5px;
  background-color: #fff;


  .boxTopTitle {
    font-size: 1.375rem;
    font-weight: 500;
    color: #000;
  }

  .boxTopValue {
    color: #000;
    font-size: 1.125rem;
    font-weight: 400;
    margin: 1.3125rem 0 1rem 0;
  }

  .dataAndObjectFilters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.75rem;

    .boxTopDateCount {
      font-size: 1rem;
      color: #4FB2ED;
      font-weight: 400;
      margin-left: 0.5rem;
    }

    .selectCode {
      max-width: 78%;
      flex-basis: 82%;
      height: 2.625rem;

      @media (max-width: 1600px) {
        max-width: 76%;
      }

      @media (max-width: 1440px) {
        max-width: 75%;
      }
    }

    .crossIcon {
      width: 2.625rem;
      height: 2.625rem;
      background-color: #EBEBEB;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.boxEventScroll {
  padding: 0 1rem 0 0;
  flex: 1;
  overflow-y: scroll;
}


.selectedBlock {
  display: flex;
  align-items: center;
}

.selectedItem {
  padding: 8.35px 12.68px 9.65px 9.5px;
  background-color: #4FB2ED;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  margin-bottom: 9.5px;
  margin-right: 9.5px;

  .selectedItemText {
    font-size: 1rem;
    font-weight: 400;
    margin-right: 27px;
  }
}


.boxTop {
  padding: 25px 29px 0 29px;
  margin-bottom: 15.5px;


  .newEvents {
    display: flex;
    align-items: center;

    .newEventsText {
      font-size: 1rem;
      font-weight: 400;
      color: #000;
      margin-right: 16px;
    }
  }
}


.filterFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectOfTypes {
  width: 100%;
}


.boxEvent {
  flex: 1;
  margin: 0 1.75rem;
  margin-top: 10px;
}


.sliderEvent {
  border: 1px solid var(--color-border-field14);
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.75rem auto 1.5rem 0.5rem;
  position: relative;

  @media (max-width: 1480px) {
    margin: 0.75rem auto 1.5rem;
  }
}

.notPayedSupplies {

  position: absolute;
  top: -12px;
  right: 9px;
  width: 9rem;

  display: flex;
  justify-content: center;
  border-radius: 9px;
  line-height: 1.5;
  padding-bottom: 2px;
  background: repeating-linear-gradient(135deg,
          #f6ad40,
          #f6ad40 10px,
          #EF9D1C 10px,
          #EF9D1C 20px);

  .notPayedSuppliesText {
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    align-self: center;
    margin-left: 8px;
    margin-top: 2px;
    white-space: nowrap;
  }

}

.NotPayedPayment {
  position: absolute;
  top: -12px;
  right: 9px;
  width: 9rem;

  display: flex;
  justify-content: center;
  border-radius: 9px;
  line-height: 1.5;
  padding-bottom: 2px;
  background: repeating-linear-gradient(135deg,
          #aa80e6,
          #aa80e6 10px,
          #9870D0 10px,
          #9870D0 20px);

  .NotPayedPaymentText {
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    align-self: center;
    margin-left: 8px;
    margin-top: 2px;
  }
}

.PayedSupplies {
  position: absolute;
  top: -12px;
  right: 9px;
  width: 5.75rem;

  display: flex;
  justify-content: center;
  border-radius: 9px;
  line-height: 1.5;
  padding-bottom: 2px;
  background-color: orange;

  .PayedSuppliesText {
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    align-self: center;
    margin-left: 8px;
    margin-top: 2px;
  }
}

.PayedPayment {
  position: absolute;
  top: -12px;
  right: 9px;
  width: 5.75rem;

  display: flex;
  justify-content: center;
  border-radius: 9px;
  line-height: 1.5;
  padding-bottom: 2px;
  background-color: #9870D0;

  .PayedPaymentText {
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    align-self: center;
    margin-left: 8px;
    margin-top: 2px;
  }
}

.cardRowsWrapper {
  overflow: hidden;
}

.supplyerFlex {
  display: flex;
  font: normal normal 400 1.125rem var(--title-font);
  margin-bottom: 17px;
  margin-top: 17px;

  .supplyerTitle {
    margin-right: 12px;
  }
}

.objectFlex {
  display: flex;
  font: normal normal 400 1.125rem var(--title-font);

  .objectTitle {
    margin-right: 50px;
    margin-bottom: 14px;
  }
}

.mountFlex {
  display: flex;
  font: normal normal 400 1.125rem var(--title-font);

  .MountTitlte {
    margin-right: 61px;
  }
}

.updFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sliderEventPay {
  border: 1px solid var(--color-border-field15);
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-top: 0.75rem;

}

.valueEvent {
  color: var(--color-border-field14);
  padding: 0;

}

.valueEventPay {
  color: var(--color-border-field15);
  padding: 0;

}

.boxEventPay {
  display: flex;
}

.boxEventSup {
  display: flex;
  border-left: 1px solid #bfbfbf;
  margin-right: 24px;
}

.sliderEventValue {
  width: 50%;
  border-right: 1px solid #BFBFBF;
  padding-right: 20px;
}

.sliderEventLink {
  display: flex;
  flex-direction: column;
}

.eventLink {
  width: 12rem;
  height: 1.9375rem;
  background-color: var(--color-background-field3);
  border-radius: 1rem;
  margin-top: 0.5rem;
  font: normal normal 400 1.125rem var(--title-font);
  display: flex;
  align-items: center;
  justify-content: center;
}

.eventLinkSupply {
  @extend .eventLink;
  background-color: var(--color-background-field13);
  cursor: pointer;
}


.sliderEvents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sliderDayTitle {
  font-size: 1.2rem;
  margin-top: .75rem;
  margin-left: .5rem;

  @media (max-width: 1480px) {
    margin-left: 0;
  }
}

.typesTitle {
  font: normal normal 400 1.125rem var(--title-font);
  margin: 20px 0 16px 0;
}


.sliderEventInfoRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  height: 2rem;
  font: normal normal 400 1.125rem var(--title-font);
}

.blockIcon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 10rem;

  margin: 0 1rem;

  span {
    border-bottom: 1px solid black;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.wrapperTypes {
  width: 100%;
  overflow-y: auto;
  height: 100vh;
}

.typesContainer {

  .storageOfTypes {
    display: flex;
    flex-wrap: wrap;
  }
}

// media styles

@media (max-width: 1600px) {
}

.scrollEventsPosition {
  width: 100%;
  margin: 0 auto;
  position: absolute;
  // top: 20px;
  left: 0;
  z-index: 1;
  background: white;
}


div.filterFlex {

  div.selectCode {
    width: 44% !important;
    height: 42px;
  }
}


@media (max-width: 1700px) {
  div.container {
    padding: 0 29px;
  }

  div.dataAndObjectFilters {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div.crossIcon {
      width: 2.7rem !important;
      height: 2.5rem !important;
      background-color: #EBEBEB;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  div.eventLink {
    width: 10rem;
    height: 1.9375rem;
    background-color: var(--color-background-field3);
    border-radius: 1rem;
    margin-top: 0.5rem;
    font: normal normal 400 1rem var(--title-font);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.eventLinkSupply {
    width: 10rem;
    height: 1.9375rem;
    background-color: var(--color-background-field3);
    border-radius: 1rem;
    margin-top: 0.5rem;
    font: normal normal 400 1rem var(--title-font);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background-field13);
    cursor: pointer;
  }

  div.blockIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 8rem;

    margin: 0 1rem;

    span {
      border-bottom: 1px solid black;
    }
  }
}


.cardRowsWrapper {

  .flexWithLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.3rem;

  }

  .row {
    display: flex;

    .wrapperTitleText {
      font: normal normal 400 1.125rem var(--title-font);
      margin-right: 12px;

      .supplyerTitle {
        margin-top: 0.5rem;
      }

      .objectTitle {
        margin-top: 0.75rem;
      }
    }

    .wrapperTitleContent {
      font: normal normal 400 1.125rem var(--title-font);

      .supplyerContent {
        margin-top: 0.5rem;
      }

      .objectContent {
        margin-top: 0.75rem;
      }
    }
  }

  .flexSupplySum {
    font: normal normal 400 1.125rem var(--title-font);
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 15px;
  }

  .supplyBorder {
    height: 1.9375rem;
    width: 1px;
    background-color: #bfbfbf;
  }

  .flexLilSupply {
    display: flex;
  }
}

.paymentWrapper {

  .flexWithLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.3rem;

  }

  .paymentBigFlex {
    display: flex;
  }

  .flexPaymentSum,
  .flexPaymentSum2 {
    font: normal normal 400 1.125rem var(--title-font);
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .flexPaymentSum2 {
    margin-top: 17px;
  }

  .paymentBorder {
    width: 1px;
    height: 62px;
    background-color: #bfbfbf;
  }
}

.marginRightPaymentAndSupply {
  margin-right: 15px;
}

@media (max-width: 1500px) {

  div.supplyerFlex,
  div.objectFlex,
  div.mountFlex {
    font: normal normal 400 1rem var(--title-font);
  }

  div.sliderEventInfoRow {
    font: normal normal 400 1rem var(--title-font);
  }

  div.selectCode {
    flex-basis: 77% !important;
  }

  div.calendarRange {
    flex-basis: 66%;
  }

  div.eventLink {
    width: 9rem;
    height: 1.9375rem;
    background-color: var(--color-background-field3);
    border-radius: 1rem;
    margin-top: 0.5rem;
    font: normal normal 400 0.9rem var(--title-font);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.eventLinkSupply {
    width: 9rem;
    height: 1.9375rem;
    background-color: var(--color-background-field3);
    border-radius: 1rem;
    margin-top: 0.5rem;
    font: normal normal 400 0.9rem var(--title-font);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background-field13);
    cursor: pointer;
  }

  div.blockIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 7rem;

    margin: 0 1rem;

    span {
      border-bottom: 1px solid black;
    }
  }
}


@media (min-width: 2000px) and (max-width: 2400px) {
  div.container {
    max-width: 550px;
  }

  div.dataAndObjectFilters {
    display: flex;
    justify-content: space-between;

    div.crossIcon {
      width: 2.6rem !important;
      height: 2.6rem !important;
      background-color: #EBEBEB;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  div.boxEventSup {
    display: flex;
    border-left: 1px solid #bfbfbf;
    margin-right: 50px;
  }


  .scrollEventsPosition {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 190px;
    left: 0;
    z-index: 1;
    background: white;
  }

  .positionTypesEvents {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 340px;
    left: 0;
    z-index: 1;
    background: white;
  }

  .container,
  .container2 {
    max-width: 600px;
    margin: 0 auto;
    background-color: #fff;
  }

  .container2 {
    margin-top: 0.75rem;
  }

  .fixedHeader {
    width: 100%;
    padding: 0 1.813rem 0 1.75rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111111;
  }


  .eventLink {
    width: 14rem;
    height: 1.9375rem;
    background-color: var(--color-background-field3);
    border-radius: 1rem;
    margin-top: 0.5rem;
    font: normal normal 400 1.125rem var(--title-font);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .eventLinkSupply {
    width: 14rem;
    height: 1.9375rem;
    border-radius: 1rem;
    margin-top: 0.5rem;
    font: normal normal 400 1.125rem var(--title-font);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background-field13);
    cursor: pointer;
  }


  .cardRowsWrapper {

    .flexWithLink {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.3rem;

    }

    .row {
      display: flex;

      .wrapperTitleText {
        font: normal normal 400 1.125rem var(--title-font);
        margin-right: 62px;

        .supplyerTitle {
          margin-top: 0.5rem;
        }

        .objectTitle {
          margin-top: 0.75rem;
        }
      }

      .wrapperTitleContent {
        font: normal normal 400 1.125rem var(--title-font);

        .supplyerContent {
          margin-top: 0.5rem;
        }

        .objectContent {
          margin-top: 0.75rem;
        }
      }
    }

    .flexSupplySum {
      font: normal normal 400 1.125rem var(--title-font);
      width: 230px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 15px;
    }

    .supplyBorder {
      height: 1.9375rem;
      width: 1px;
      background-color: #bfbfbf;
    }

    .flexLilSupply {
      display: flex;
    }
  }

  .paymentWrapper {

    .flexWithLink {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.3rem;
    }

    .paymentBigFlex {
      display: flex;
    }

    .flexPaymentSum,
    .flexPaymentSum2 {
      font: normal normal 400 1.125rem var(--title-font);
      width: 230px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .flexPaymentSum2 {
      margin-top: 17px;
    }

    .paymentBorder {
      width: 1px;
      height: 62px;
      background-color: #bfbfbf;
    }
  }

  .marginRightPaymentAndSupply {
    margin-right: 15px;
  }

}

@media (min-width: 2401px) and (max-width: 2700px) {

  .scrollEventsPosition {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 190px;
    left: 0;
    z-index: 1;
    background: white;
  }

  .positionTypesEvents {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 340px;
    left: 0;
    z-index: 1;
    background: white;
  }

  .container,
  .container2 {
    max-width: 650px;
    margin: 0 auto;
    background-color: #fff;
  }

  .container2 {
    margin-top: 0.75rem;
  }

  .fixedHeader {
    width: 100%;
    padding: 0 1.813rem 0 1.75rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111111;
  }


  .eventLink {
    width: 15rem;
    height: 1.9375rem;
    background-color: var(--color-background-field3);
    border-radius: 1rem;
    margin-top: 0.5rem;
    font: normal normal 400 1.125rem var(--title-font);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .eventLinkSupply {
    width: 15rem;
    height: 1.9375rem;
    border-radius: 1rem;
    margin-top: 0.5rem;
    font: normal normal 400 1.125rem var(--title-font);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background-field13);
    cursor: pointer;
  }


  .cardRowsWrapper {

    .flexWithLink {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.3rem;

    }

    .row {
      display: flex;

      .wrapperTitleText {
        font: normal normal 400 1.125rem var(--title-font);
        margin-right: 62px;

        .supplyerTitle {
          margin-top: 0.5rem;
        }

        .objectTitle {
          margin-top: 0.75rem;
        }
      }

      .wrapperTitleContent {
        font: normal normal 400 1.125rem var(--title-font);

        .supplyerContent {
          margin-top: 0.5rem;
        }

        .objectContent {
          margin-top: 0.75rem;
        }
      }
    }

    .flexSupplySum {
      font: normal normal 400 1.125rem var(--title-font);
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 15px;
    }

    .supplyBorder {
      height: 1.9375rem;
      width: 1px;
      background-color: #bfbfbf;
    }

    .flexLilSupply {
      display: flex;
    }
  }

  .paymentWrapper {

    .flexWithLink {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.3rem;

    }

    .paymentBigFlex {
      display: flex;
    }

    .flexPaymentSum,
    .flexPaymentSum2 {
      font: normal normal 400 1.125rem var(--title-font);
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .flexPaymentSum2 {
      margin-top: 17px;
    }

    .paymentBorder {
      width: 1px;
      height: 62px;
      background-color: #bfbfbf;
    }
  }

  .marginRightPaymentAndSupply {
    margin-right: 15px;
  }


  div.boxEventSup {
    display: flex;
    border-left: 1px solid #bfbfbf;
    margin-right: 90px;
  }

  div.container {
    max-width: 650px;
  }

  div.dataAndObjectFilters {
    display: flex;
    justify-content: space-between;

    div.crossIcon {
      width: 2.7rem !important;
      height: 2.5rem !important;
      background-color: #EBEBEB;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

@media (min-width: 1701px) and (max-width: 1999px) {
  .scrollEventsPosition {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 190px;
    left: 0;
    z-index: 1;
    background: white;
  }

  .positionTypesEvents {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 340px;
    left: 0;
    z-index: 1;
    background: white;
  }

  .container,
  .container2 {
    max-width: 498px;
    margin: 0 auto;
    background-color: #fff;
  }

  .container2 {
    margin-top: 0.75rem;
  }

  .fixedHeader {
    width: 100%;
    padding: 0 1.813rem 0 1.75rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111111;
  }


  .eventLink {
    width: 12rem;
    height: 1.9375rem;
    background-color: var(--color-background-field3);
    border-radius: 1rem;
    margin-top: 0.5rem;
    font: normal normal 400 1.125rem var(--title-font);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .eventLinkSupply {
    width: 12rem;
    height: 1.9375rem;
    border-radius: 1rem;
    margin-top: 0.5rem;
    font: normal normal 400 1.125rem var(--title-font);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background-field13);
    cursor: pointer;
  }


  .cardRowsWrapper {

    .flexWithLink {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.3rem;

    }

    .row {
      display: flex;

      .wrapperTitleText {
        font: normal normal 400 1.125rem var(--title-font);
        margin-right: 32px;

        .supplyerTitle {
          margin-top: 0.5rem;
        }

        .objectTitle {
          margin-top: 0.75rem;
        }
      }

      .wrapperTitleContent {
        font: normal normal 400 1.125rem var(--title-font);

        .supplyerContent {
          margin-top: 0.5rem;
        }

        .objectContent {
          margin-top: 0.75rem;
        }
      }
    }

    .flexSupplySum {
      font: normal normal 400 1.125rem var(--title-font);
      width: 230px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 15px;
    }

    .supplyBorder {
      height: 1.9375rem;
      width: 1px;
      background-color: #bfbfbf;
    }

    .flexLilSupply {
      display: flex;
    }
  }

  .paymentWrapper {

    .flexWithLink {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.3rem;

    }

    .paymentBigFlex {
      display: flex;
    }

    .flexPaymentSum,
    .flexPaymentSum2 {
      font: normal normal 400 1.125rem var(--title-font);
      width: 230px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .flexPaymentSum2 {
      margin-top: 17px;
    }

    .paymentBorder {
      width: 1px;
      height: 62px;
      background-color: #bfbfbf;
    }
  }

  .marginRightPaymentAndSupply {
    margin-right: 15px;
  }

}

.taskStatus {
  background: #73C0E3 !important;
  justify-content: flex-start;
  padding: 0 0.5rem 2px;
  width: auto;
}

.title, .value {
  font-size: 1.125rem;
  font-weight: 400;
}

.title {
  margin-right: 0.75rem;
}

.value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.task {
  gap: 0.75rem 0;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.tabs {
  display: flex;
  justify-content: center;
  width: 100%;
}

.tabsContainer {
  width: 100%;
  margin-top: 0.75rem;
}

.tab {
  flex-basis: 50%;
}

.placeholder {
  margin-top: 4rem;
}