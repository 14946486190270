.link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    height: 3.38rem;
    width: 3.94rem;
}

.active {
    background-color: #e8e8e8;
    border-radius: 4px;
    border-left: solid 6px #4fb2ed;
}
