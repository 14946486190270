.taskFiles {
  display: flex;
  flex-direction: column;
  row-gap: .5rem;
}

.filesHead {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & span {
    font-weight: 500;
  }

  & div {
    color: #4FB2ED;
    cursor: pointer;
  }
}