
.ksModal{
  max-width: 92.5%;
}

.ksContent {
  font-family:'Times New Roman', Times, serif;
  color: #000;
  padding: 1rem;

  h1 {
    text-align: center;
  }
  .table {
    border: 1px solid #000;

    td {
      border: 1px solid #000;
      text-align: center;
      padding: .2rem .4rem;
    }
  }
  .actionBlock {
    padding: 1rem 0;
    text-align: right;
  }
}