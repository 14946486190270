.addFilesModal {
  width: calc(min(560px, 80vw));
}

.addFilesModal__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.addFilesModal__form {
  padding: 25px 21px 30px 48px;
}

.addFilesModal__form_title {
  font-size: 22px;
  color: #000000;
  margin-bottom: 9px;
}

.addFilesModal__form_subtitle {
  font-size: 16px;
  color: #8d8d8d;
}

.addFilesModal__files {
  margin-top: 20px;
}

.addFilesModal__files_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;

  .title {
    font-size: 16px;
    font-weight: 600;
  }

  .actionButton {
    color: #4FB2ED;
    font-size: 16px;
    cursor: pointer;
  }
}

.addFilesModal__buttons {
  display: flex;
  justify-content: center;
  gap: 17px;
  padding: 17px 33px;
  border-top: 1px solid #bcbcbc;
}

.paperclipIcon {
  position: relative;
  width: 4rem;
  cursor: pointer;

  .paperclipIcon__count {
    position: absolute;
    top: -0.4rem;
    left: 1.1rem;
    background-color: var(--color-background-primary);
    font: normal normal 400 0.87rem var(--title-font);
    color: var(--color-font-hover);
    border-radius: 1rem;
    min-width: 1.57rem;
    padding: 0.05rem 0;
    text-align: center;
    top: -0.2em;
    left: 0.9rem;
  }

  &.secondary {
    width: 3rem;
    background: #EDEDED;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;

    .paperclipIcon__count {
      position: static;
      color: var(--color-font-main);
      background: none;
      min-width: auto;
    }

    svg {
      opacity: 0.5;
    }
  }
}
