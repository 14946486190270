.tableHeader {
    font-weight: 500;
    display: grid;
    grid-template-columns: 8% 26% 16% 8% 14% 14% 14%;
    background-color: #fff;
    z-index: 3;
    border-bottom: 1px solid #4fb2ed;
    margin-bottom: 0.3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.88rem;
    height: 3.88rem;

  &.sticky {
    position: sticky;
    top: 2rem;
    z-index: 2;
  }
}

.sticky {
  position: sticky;
  top: 3.5rem;
}

.headerCell {
  white-space: nowrap;
  display: flex;
  align-items: center;
  min-height: 1.875rem;
}

.centered {
  text-align: center;
  justify-content: center;
}

.righted {
  text-align: right;
  justify-content: flex-end;
  padding-right: 3rem;
}