.labelBox {
  display: flex;
  margin: 0.5rem 0;
  flex-wrap: nowrap;

  & > *:not(:last-child) {
    margin-right: 0.625rem;
  }

  .blueLabelWrapper {
    width: 11.125rem;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }

  .blueLabel {
    color: #4FB2ED;
    background-color: #e6f4fc;
    border-radius: 50px;
    padding: 0.2rem 0.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 14rem;
    display: flex;
    align-items: center;
  }

  .greyLabel {
    color: #808080;
    background-color: #ededed;
    border-radius: 50px;
    padding: 0.2rem 0.6rem;
    white-space: nowrap;
    height: min-content;
    cursor: pointer;

    svg {
      margin-right: 0.2rem;
    }
  }
}

.cursorPointer {
  cursor: pointer;
}

@media screen and (max-width: 1441px) {
  .labelBox {
    flex-wrap: nowrap !important;
  }
}