.header {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  column-gap: 1rem;

  &::before {
    content: "";
    position: absolute;
    width: 2rem;
    left: -2rem;
    height: 100%;
    background-color: #fff;
    top: 0;
  }
}

.titleValue {
  font-size: 1.5rem;
  margin-right: 3rem;
  font-family: var(--title-font);
  font-weight: 500;
  color: black;
}

.title {
  font-size: 1.71rem;
  font-family: var(--title-font);
  font-weight: 500;
  color: #000;
}

.tab {
  flex-basis: 9.75rem !important;
  width: 9.75rem !important;
  flex-shrink: 0;
  justify-content: center;
}

.tabs, .button, .dateSlider {
  height: 2.813rem;
}

.dateSlider{
  margin-right: auto;
}


@media screen and (max-width: 1441px) {
  .tab {
    flex-basis: 8.5rem !important;
    width: 8.5rem !important;
  }

  .button {
    font-size: 1rem !important;
  }
}

.templateNarrow{
  padding: 1.88rem 1rem;
}

.headerNarrow{
  padding: 0 1.76rem 0 3.88rem;
  margin-bottom: 0;
}

.ks6aButton{
  font-size: 1rem;
  padding: .7125rem 2.31rem;
}

.measureButton{
  min-width: 9.875rem;
}