.container {
  border-radius: 0.5rem;
  background-color: #fff;
  position: relative;
  height: 1px;
  width: calc(100% + 1rem);
  border-radius: 0.5rem;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: 0.5rem;

  .content {
    height: 100%;
    overflow: hidden;
    animation: fading .3s linear;
  }

  .circleBlock {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 0.375rem;

    @media screen and (max-width: 1440px) {
      padding-bottom: 0rem;
    }

    .divider {
      width: 1px;
      background-color: #c9c9c9;
      margin: 0 2.5rem 0 0;
      height: 3.5rem;
    }

    .item {
      height: 100%;
      display: flex;
      width: 16rem;
      justify-content: space-between;
      align-items: center;

      .circleTitle {
        display: flex;
        font-size: 1.25rem;
        margin-bottom: 0.393rem;
      }

      .circleDescription {
        font-size: 1.25rem;
      }
    }
  }
}

.button {
  border-radius: 10px;
  background-color: #4fb2ed;
  padding: 0.5rem 1.2rem 0.5rem 0.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: var(--main-font);
  color: #fff;

  .arrowLeft {
    height: 0.8rem;
    margin-right: 1rem;
  }
}

.backTitle {
  display: flex;
  align-items: center;
}

.btn {
  position: absolute;
  left: -1.08rem;
  top: 0.5rem;
  width: 1.1rem;
  height: 4.7rem;
  background-color: #efefef;
  border-radius: 5px;
}

.activeBtn {
  border-radius: 5px 0 0 5px;
  background-color: #4fb2ed;

  & svg {
    transform: scaleX(-1);

    & path {
      fill: #fff;
    }
  }
}

.open {
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  height: auto;
  margin-bottom: 1rem;
}

@keyframes fading {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}