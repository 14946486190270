.approval {
  padding: 1rem 3.714rem;
  margin-bottom: -1.5rem;
  font: normal normal 1.125rem/1.375rem var(--title-font);

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 1.286rem;
  }

  .headerText {
    margin: 0 1rem 0 0;
    font: normal normal 400 1.125rem/1.375rem var(--title-font);
  }

  .elements {
    flex-wrap: wrap;
  }

  .elements, .elementContainer {
    display: flex;
    align-items: center;
  }

  .elementContainer {
    position: relative;
  }
}

.chainIcon, .approvedChain {
  position: absolute;
  top: 32px;
  right: 4px;
}

.approvedChain {
  background-color: var(--color-chain-line);
  width: 4.5rem;
  height: 4px;
  right: 0;
  z-index: 1;
}

.element {
  z-index: 2;
}