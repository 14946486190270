.inputCount {
    width: 63%;
    margin-right: 1rem;
    background-color: #fff;
    border: none;

    &.inputActive {
      background-color: #ccecff;
    }
  }

  .arrow {
    display: block;
    opacity: 0;

    &.active {
      cursor: pointer;
      opacity: 1;
    }
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }