.container {
  & table {
    width: 100%;
  }

  .actionBlock {
    display: flex;
    align-items: center;
    padding: 2rem 0;

    & > :first-child {
      margin-right: 1rem;
    }
  }

  .plusIcon {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }

  .addButtonClass {
    display: flex;
    align-items: center;
  }
}

.active {
  margin: 0;
}

.containerBtn {
  display: flex;
  align-items: center;
  margin-bottom: 1.88rem;

  span {
    font: normal normal 400 1.125rem var(--title-font);
  }

  .button {
    border-radius: 10px;
    background-color: #4fb2ed;
    padding: 0.5rem 1.2rem 0.5rem 0.8rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: var(--main-font);
    color: #fff;
    margin-right: 2rem;

    .arrowLeft {
      // transform: rotate(90deg);
      // -webkit-transform: rotate(90deg);
      height: 0.8rem;
      margin-right: 1rem;
    }
  }
}

.containerBtnActive {
  @extend .containerBtn;
  // margin: 1.88rem 4.88rem;
}
