.container {
  max-width: 22.75rem;
  min-width: 22.75rem;
  padding: 0.563rem 0 0.938rem;

  @media screen and (max-width: 1680px) {
    max-width: 21.2rem;
    min-width: 21.2rem;
  }
}

.header {
  max-width: 20rem;
  padding-left: 0.813rem;

  & h1 {
    font-size: 1rem;
    font-weight: 400;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e3e3e3;
}

.section {
  padding: 0.563rem 0.375rem 0.875rem;
}

.item {
  padding: 0.563rem 1rem 0.75rem 0.625rem;
  border: 1px solid var(--color-background-field11);
  background-color: #f8f8f8;
  border-radius: 0.375rem;
  cursor: pointer;

  &:first-child {
    margin-bottom: 0.563rem;
  }

  &.active {
    border-color: #aadfff;
    background-color: #ddf2ff;
    cursor: default;
  }
}

.projectContent {
  width: 90%;
}

.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.input {
  width: 9.75rem;
}

.radioName {
  max-width: 6.875rem;
  font-weight: 400;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;

  & button {
    display: flex;
    justify-content: center;
    font-size: 1rem;
    width: 8.75rem;
    padding: 0;

    &:first-child {
      margin-right: 0.438rem;
    }
  }
}
