.container {
  display: flex;
  flex-direction: column;
  position: relative;
  font: normal normal 1rem/1.125rem var(--title-font);
}

.input {
  height: 2.64rem;
  border-radius: 0.5rem;
  padding: 0.7rem 1rem;

  &.primary {
    border: 1px solid var(--color-border-field18);

    &:focus, &:active {
      border-color: var(--color-border-primary);
    }
  }

  &.secondary {
    border: none;
    background-color: var(--color-background-field5);
    color: var(--color-font-main);

    ::placeholder {
      color: var(--color-font-main);
      opacity: 0.5;
    }
  }

  &.brandColorTransparent {
    border: 1px solid var(--color-border-primary);
    background-color: transparent;

    &:focus, &:active {
      border-color: var(--color-border-primary);
    }
  }

  &.brandColorWhite {
    border: 1px solid var(--color-border-primary);
    background-color: #fff;

    &:focus, &:active {
      border-color: var(--color-border-primary);
    }
  }

  &.price {
    text-align: right;
  }

  &.price::placeholder {
    text-align: left;
  }
}

.inputError {
  border-color: #FF0558;
}

.errorMessage {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #FF0558;
  position: absolute;
  bottom: -1.25rem;
}

.label {
  color: #666666;
  margin-bottom: 0.35rem;
}

.tertiary {
  background-color: #EFEFEF;
  border: none;
  font: normal normal 1.5rem/1.875rem var(--title-font);
  color: var(--color-font-main);
}