.addButton {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 30px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}