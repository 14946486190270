.container {
  cursor: pointer;
  position: relative;
}

.selectedSection {
  display: flex;
  align-items: center;
  width: 12rem;
  height: 2.5625rem;
}

.text {
  color: var(--color-font-primary);
  overflow: hidden;
  word-break: break-all;
}

.errorMessage {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #FF0558;
  position: absolute;
  bottom: 1rem;
}