.container {
    width: 2rem;
    height: 2rem;
    max-height: 100%;
    position: relative;
    padding-right: 0.5rem;
    flex-shrink: 0;
}

.icon {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.attention {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    color: red;
    font-size: 1.7rem;
}

.black {
    background-image: url("./black.svg");
}

.green {
    background-image: url("./green.svg");
}

.blue {
    background-image: url("./blue.svg");
}