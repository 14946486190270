.container {
  // position: relative;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
}

.titleRow {
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 400;
    font-size: 1.125rem;
    font-family: var(--title-font);
    color: var(--color-font-secondary1);
  }
}

.tabs {
  margin-bottom: 1rem;
}
