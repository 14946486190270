.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
  position: relative;
  margin-left: 1rem;

  .button {
    position: absolute;
    cursor: pointer;

    &.isOpen {
      z-index: 12;
    }
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;

    .top {
      position: absolute;
      top: -1rem;
      left: -1rem;
      width: 4rem;
      height: 3rem;
      border-radius: 0.5rem 0.5rem 0 0;
      background-color: var(--color-background-main);
      border: 1px solid var(--color-border-primary);
      border-bottom: 0;
      z-index: 9;
    }
    .bottom {
      position: absolute;
      top: calc(3rem - 1px);
      left: -1rem;
      border-radius: 0 0 0.5rem 0.5rem;
      background-color: var(--color-background-main);
      border: 1px solid var(--color-border-primary);
      z-index: 8;    
      margin-top: -1rem;
      font: normal normal 400 1rem var(--title-font);
      padding: 0.5rem 0;

      .title {
        cursor: default;
        color: #707070;
        padding: 0.5rem 0.9rem;
      }
      .buttonForm {
        padding: 0.5rem 0.9rem;
        cursor: pointer;
      }
    }
  }
}