.tableHeader {
  font: normal normal 500 1rem var(--main-font);
  border-bottom: 1px solid var(--color-background-field7);
  display: grid;
  grid-template-columns: 8% 26% 20% 15% 15% 16%;
  padding-bottom: 0.5rem;

  .dot {
    background: var(--color-background-field8);
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 10rem;
  }

  .rowItem {
    white-space: nowrap;
    display: flex;
    align-items: center;
  }

  .fieldNumber {
    justify-content: center;
  }

  .fieldTitle {
    flex: 1;
  }
}

.measure {
  text-align: center;
  padding: 0 1rem !important;
}

.name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
}