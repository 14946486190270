.link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;

  &:nth-child(2) {
    .count {
      right: -6px;
    }
    .container {
      width: 22px;
      height: 22px;
    }
  }
}

.notyCounterBlock {
  position: relative;
  top: 2px;
  left: 0;
  cursor: pointer;

  .notyCounter {
    position: absolute;
    padding: 0.125rem 0.375rem;
    background-color: #ff6fa1;
    border-radius: 0.75rem;
    top: 7%;
    right: -16%;
    font-size: 0.688rem;
    font-weight: 500;
    color: #fff;
    z-index: 1;
  }

  .notyCounterLess {
    position: absolute;
    padding: 0.125rem 0.375rem;
    background-color: #ff6fa1;
    border-radius: 0.75rem;
    top: 7%;
    right: 4%;
    font-size: 0.688rem;
    font-weight: 500;
    color: #fff;
    z-index: 1;
  }
}

.container {
  position: relative;
  height: 3.38rem;
  width: 3.94rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-left: solid 6px transparent;

  &.active {
    background-color: #e8e8e8;
    border-radius: 4px;
    border-left: solid 6px #4FB2ED;
  }

  .icon {
    width: 100%;
    height: 100%;
  }
}

.active .container {
  background-color: #e8e8e8;
  border-radius: 4px;
  border-left: solid 6px #4FB2ED;
}
